import React from 'react'
import { NormalizedCacheObject } from '@apollo/client'
import { useRouter } from 'next/router'
import { Toaster } from 'src/components/ui/toaster'
import { useToast } from 'src/components/ui/use-toast'
import { ApolloProvider } from './ApolloProvider'
import { AuthProvider } from './auth'
import { ThemeProvider } from './ThemeProvider'

interface Props {
  children: React.ReactNode
  initialApolloState: NormalizedCacheObject
}

export const AppProviders: React.FC<Props> = ({ children, initialApolloState }) => {
  const router = useRouter()
  const { toast } = useToast()
  const [lastForcedUnauthRender, setLastForcedUnauthRender] = React.useState(Date.now())

  return (
    <ApolloProvider
      initialApolloState={initialApolloState}
      onError={(error) => {
        toast({ variant: 'destructive', title: 'Error', description: error })
      }}
      onUnauthError={() => {
        void router.push('/')
        setLastForcedUnauthRender(Date.now())
      }}
      key={lastForcedUnauthRender}
    >
      <AuthProvider>
        <ThemeProvider
          attribute="class"
          defaultTheme="system"
          enableSystem
          disableTransitionOnChange
        >
          {children}
          <Toaster />
        </ThemeProvider>
      </AuthProvider>
    </ApolloProvider>
  )
}
