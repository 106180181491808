/* eslint-disable @typescript-eslint/naming-convention */
import React, { HTMLAttributes } from 'react'
import classnames from 'classnames'
import { cn } from 'src/components/utils/utils'

export enum FontSize {
  'xs' = 'text-xs',
  'sm' = 'text-sm',
  'base' = 'text-base',
  'md' = 'text-md',
  'lg' = 'text-lg',
  'xl' = 'text-xl',
  '2xl' = 'text-2xl',
  '3xl' = 'text-3xl',
  '4xl' = 'text-4xl',
  '5xl' = 'text-5xl',
  '6xl' = 'text-6xl',
  '7xl' = 'text-7xl',
  '8xl' = 'text-8xl',
  '9xl' = 'text-9xl',
}

export type FontSizeType = keyof typeof FontSize

export enum FontWeight {
  'light' = 'font-light',
  'normal' = 'font-normal',
  'medium' = 'font-medium',
  'semi' = 'font-semibold',
  'bold' = 'font-bold',
  'extra' = 'font-bolder',
}
export type FontWeightType = keyof typeof FontWeight

export enum HeadingFontSize {
  'h1' = 'text-6xl md:text-7xl lg:text-8xl leading-tighter',
  'h2' = 'text-4xl md:text-5xl lg:text-6xl leading-tighter',
  'h3' = 'text-3xl md:text-4xl lg:text-5xl leading-tighter',
  'h4' = 'text-2xl md:text-3xl leading-tighter',
  'h5' = 'text-xl lg:text-2xl leading-tighter',
  'h6' = 'text-lg md:text-xl leading-tighter',
}

export enum HeadingFontStyle {
  'default' = '',
  'serif' = 'font-serif',
  'hand' = 'font-hand',
  'sans' = 'font-sans',
}

export type HeadingFontSizeType = keyof typeof HeadingFontSize

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode
  tag?: keyof typeof HeadingFontSize
  size?: keyof typeof HeadingFontSize
  headingStyle?: keyof typeof HeadingFontStyle
  weight?: FontWeightType
  className?: string
}

export const Heading = ({
  children,
  tag = 'h2',
  weight = 'light',
  size,
  headingStyle = 'default',
  className,
  ...props
}: HeadingProps) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const Component = tag

  return (
    <Component
      className={classnames(
        HeadingFontSize[size ?? tag],
        HeadingFontStyle[headingStyle],
        FontWeight[weight],
        className
      )}
      {...props}
    >
      {children}
    </Component>
  )
}

export interface TextProps extends HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode
  size?: FontSizeType
  weight?: FontWeightType
  as?: 'p' | 'span'
  className?: string
}

export type TextOptions = Omit<TextProps, 'children'>

export const Text = ({
  children,
  className,
  size = 'base',
  weight = 'normal',
  as = 'p',
  ...props
}: TextProps) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const Component = as

  return (
    <Component
      className={classnames('font-mono', FontSize[size], FontWeight[weight], className)}
      {...props}
    >
      {children}
    </Component>
  )
}

export const H1 = React.forwardRef<HTMLHeadingElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, children, ...props }, ref) => (
    <h1
      ref={ref}
      className={cn(
        'font-serif scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl',
        className
      )}
      {...props}
    >
      {children}
    </h1>
  )
)

H1.displayName = 'Card'
