import React, { useMemo } from 'react'
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as Provider,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import fetch from 'cross-fetch'
import { uniq } from 'lodash'
import merge from 'lodash/merge'
import { GetServerSidePropsContext } from 'next'

export const GqlErrorCode = {
  InternalSeverError: 'InternalSeverError',
  Unauthorized: 'Unauthorized',
  NotAuthorized: 'NotAuthorized',
  SignUpEmailExists: 'SignUpEmailExists',
  SignUpUserNameExists: 'SignUpUserNameExists',
  InvalidSignUpUserName: 'InvalidSignUpUserName',
  LoginWrongCredentials: 'LoginWrongCredentials',
  NotFound: 'NotFound',
  RequestPwdResetEmailNotExists: 'RequestPwdResetEmailNotExists',
  ResetPwdTokenNotExist: 'ResetPwdTokenNotExist',
  ChangePwdWrongCredentials: 'ChangePwdWrongCredentials',
  PostDoesNotExist: 'PostDoesNotExist',
  PostRoomNameExists: 'PostRoomNameExists',
  PostRoomDoesNotExist: 'PostRoomDoesNotExist',
}

export interface IErrors {
  graphQlErrors: { [key in keyof typeof GqlErrorCode]: string }
  networkError: string
}

export const ERRORS: IErrors = {
  graphQlErrors: {
    InternalSeverError: 'Internal server error.',
    Unauthorized: 'Unauthorized',
    NotAuthorized: 'Not Authorized',
    SignUpEmailExists: 'The email already exists',
    SignUpUserNameExists: 'The username already exists',
    InvalidSignUpUserName: 'Invalid username',
    NotFound: 'Content not found.',
    LoginWrongCredentials: 'Invalid email or password',
    RequestPwdResetEmailNotExists: 'The email does not exist',
    ResetPwdTokenNotExist: 'Invalid token',
    ChangePwdWrongCredentials: 'Passwords do not match',
    PostDoesNotExist: 'Post does not exist',
    PostRoomNameExists: 'Post room with that name exists',
    PostRoomDoesNotExist: 'Room does not exist',
  },
  networkError: 'Connection lost',
}

interface ApolloProviderProps {
  children: React.ReactNode
  onError?: (error: string) => void
  onUnauthError?: () => void
  initialApolloState: NormalizedCacheObject
}

let globalApolloClient: ApolloClient<NormalizedCacheObject>

function createApolloClient(
  { onError: handleError, onUnauthError }: Partial<ApolloProviderProps>,
  context?: GetServerSidePropsContext
) {
  const { headers } = context?.req || {}

  const httpLink = createHttpLink({
    uri: process.env.NEXT_PUBLIC_API_URL,
    credentials: 'same-origin',
    fetch,
    headers: {
      cookie: headers?.cookie ?? '',
    },
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      uniq(graphQLErrors.map(({ message }) => message)).map((message) => {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log(message)
        }
        return handleError?.(
          ERRORS.graphQlErrors[message as keyof IErrors['graphQlErrors']] ?? 'Server error'
        )
      })

      const isUserUnauthorized = graphQLErrors.some(
        ({ message }) => message === ERRORS.graphQlErrors.Unauthorized
      )
      if (isUserUnauthorized) {
        onUnauthError?.()
      }
    }
    if (networkError) {
      handleError?.(ERRORS.networkError)
    }
  })
  const link = (() => ApolloLink.from([errorLink, httpLink]))()

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        nextFetchPolicy: 'cache-and-network',
      },
    },
  })
}

export function initializeApollo(
  props: Partial<ApolloProviderProps> = {},
  context?: GetServerSidePropsContext
) {
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    const apolloClient = createApolloClient(props, context)
    if (props.initialApolloState) {
      apolloClient.cache.restore(props.initialApolloState)
    }
    return apolloClient
  }

  if (!globalApolloClient) {
    globalApolloClient = createApolloClient(props, context)
  }

  // gets hydrated here
  if (props.initialApolloState) {
    globalApolloClient.cache.restore(props.initialApolloState)
  }

  // Create the Apollo Client once in the client
  return globalApolloClient
}

export function useApollo(props: ApolloProviderProps) {
  return useMemo(() => {
    if (globalApolloClient && props.initialApolloState) {
      const before = globalApolloClient.cache.extract()
      const merged = merge({}, before, props.initialApolloState)
      globalApolloClient.restore(merged)
    }
    return globalApolloClient ?? initializeApollo(props)
  }, [props])
}

export const ApolloProvider = ({
  children,
  onError,
  onUnauthError,
  initialApolloState,
}: ApolloProviderProps) => {
  const client = useApollo({
    children,
    onError,
    onUnauthError,
    initialApolloState,
  })
  return <Provider client={client}>{children}</Provider>
}
