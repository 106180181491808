import React from 'react'
import { UserRoleEnum } from '../../../generated/graphql'
import { useAuth } from './auth'
import { useOnlineStatus } from './useOnlineStatus'

export const AuthGroups = {
  AllAuth: [UserRoleEnum.User, UserRoleEnum.SuperAdmin],
  SuperAdminOnly: [UserRoleEnum.SuperAdmin],
}

export interface UseAuthProtectionProps {
  authGroup: null | keyof typeof AuthGroups
  onUnAuth?: () => void
}

export const useAuthProtection = ({ authGroup, onUnAuth }: UseAuthProtectionProps) => {
  const { isLoading, isLoggedIn, hasRoles } = useAuth()
  const isOnline = useOnlineStatus()
  const hasUserAuthorization = authGroup === null || hasRoles(AuthGroups[authGroup])

  React.useEffect(() => {
    if (onUnAuth && isOnline && !hasUserAuthorization && !isLoading) {
      onUnAuth()
    }
  }, [onUnAuth, hasUserAuthorization, isLoading, isOnline])

  return React.useMemo(
    () => ({
      isLoading,
      isLoggedIn,
      hasUserAuthorization,
      isOnline,
    }),
    [isLoading, isLoggedIn, hasUserAuthorization, isOnline]
  )
}
