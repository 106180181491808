import React from 'react'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { NextPageWithLayout } from '../src/types/general'
import { AppProviders } from '../src/utils/AppProviders'
import { NotificationProvider } from '../src/utils/NotificationProvider'
import '../src/components/TipTapEditor/proseMirror.css'
import '../styles/global.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const DynamicNotifications = dynamic(
  () =>
    import('../src/components/Notifications/Notifications').then((module) => module.Notifications),
  {
    ssr: false,
  }
)

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
        <meta name="application-name" content="CommunityPlattform" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="CommunityPlattform" />
        <meta name="description" content="CommunityPlattform" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-config" content="/icons/browserconfig.xml" />
        <meta name="msapplication-TileColor" content="#F6F6F6" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#F6F6F6" />

        <link rel="apple-touch-icon" href="/touch-icon-iphone.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/icons/touch-icon-ipad.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/icons/touch-icon-iphone-retina.png" />
        <link rel="apple-touch-icon" sizes="167x167" href="/icons/touch-icon-ipad-retina.png" />

        <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#5bbad5" />
        <link rel="shortcut icon" href="/favicon.ico" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content={`${process.env.NEXT_PUBLIC_APP_URL}`} />
        <meta name="twitter:title" content="CommunityPlattform" />
        <meta name="twitter:description" content="CommunityPlattform" />
        <meta
          name="twitter:image"
          content={`${process.env.NEXT_PUBLIC_APP_URL}/icons/android-chrome-192x192.png`}
        />
        {/* <meta name="twitter:creator" content="@" /> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="CommunityPlattform" />
        <meta property="og:description" content="CommunityPlattform" />
        <meta property="og:site_name" content="CommunityPlattform" />
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_APP_URL}`} />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_APP_URL}/icons/apple-touch-icon.png`}
        />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <link
          href="https://fonts.googleapis.com/css2?family=Handlee&family=Comic+Neue:wght@300;400;700&family=Noto+Sans+Mono:wght@300;400;700&family=Expletus+Sans:wght@400;600&family=Noto+Serif:wght@300;400;600&display=swap"
          rel="stylesheet"
        />
      </Head>
      <NotificationProvider>
        <AppProviders {...pageProps}>{getLayout(<Component {...pageProps} />)}</AppProviders>
        <DynamicNotifications />
      </NotificationProvider>
    </>
  )
}

export default MyApp
