import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  Notification,
  NotificationProps,
  NotificationTypeType,
} from '../components/Notifications/Notification'

interface INotification {
  note: ReactElement<NotificationProps>
  id: string
}

interface INotificationContext {
  addNotification: (
    notificationType: NotificationTypeType,
    title: string,
    description: string
  ) => void
  removeNotification: (noteId: string) => void
  notifications: INotification[]
}

interface INotificationProviderProps {
  children: ReactNode
}

const NotificationContext = createContext<INotificationContext>({
  addNotification: () => {},
  removeNotification: () => {},
  notifications: [],
})

export const useNotification = () => useContext(NotificationContext)

export const NotificationProvider = ({ children }: INotificationProviderProps) => {
  const [notifications, setNotifications] = useState<INotification[]>([])

  const addNotification = useCallback(
    (notificationType: NotificationTypeType, title: string, description: string) => {
      const id = uuidv4()
      setNotifications((pvState) => [
        {
          note: (
            <Notification
              notificationType={notificationType}
              title={title}
              description={description}
              id={id}
            />
          ),
          id,
        },
        ...pvState,
      ])
    },
    []
  )

  const removeNotification = useCallback((noteId: string) => {
    setNotifications((pvState) => pvState.filter((note) => note.id !== noteId))
  }, [])

  useEffect(() => {
    if (notifications.length > 5) {
      removeNotification(notifications[notifications.length - 1].id)
    }
  }, [notifications, removeNotification])

  const contextValue: INotificationContext = React.useMemo(
    () => ({
      addNotification,
      removeNotification,
      notifications,
    }),
    [addNotification, removeNotification, notifications]
  )

  return (
    <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>
  )
}
