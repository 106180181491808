import { RxCross2 } from 'react-icons/rx'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { useNotification } from '../../../utils/NotificationProvider'
import { Heading, Text } from '../../ui/Typography'

const NOTIFICATION_DURATION_IN_S = 6

export enum NotificationType {
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
  Success = 'Success',
}

export type NotificationTypeType = keyof typeof NotificationType

export enum NotificationTextColors {
  Error = 'text-danger-600',
  Warning = 'text-primary-300',
  Info = 'text-info-300',
  Success = 'text-success-300',
}

export enum NotificationBgColors {
  Error = 'bg-danger-600',
  Warning = 'bg-primary-300',
  Info = 'bg-info-300',
  Success = 'bg-success-300',
}

export interface NotificationProps {
  notificationType: NotificationTypeType
  title: string
  description: string
  id: string
}

export const Notification = ({ title, description, id, notificationType }: NotificationProps) => {
  const { removeNotification } = useNotification()

  return (
    <div
      className="relative space-y-2 overflow-hidden bg-white border rounded-md shadow-2xl pointer-events-auto border-neutral-250"
      onClick={() => removeNotification(id)}
    >
      <div className="relative flex items-center justify-between px-4 pt-2">
        <Heading
          className={classNames(NotificationTextColors[notificationType])}
          tag="h3"
          weight="bold"
        >
          {title}
        </Heading>

        <RxCross2
          className="text-xl cursor-pointer text-neutral-400"
          onClick={() => removeNotification(id)}
        />
      </div>
      <div className="px-4 ">
        <Text className="text-neutral-400" size="lg">
          {description}
        </Text>
      </div>
      <motion.div
        className={classNames('h-2', NotificationBgColors[notificationType])}
        initial={{
          width: 0,
        }}
        animate={{
          width: `100%`,
          transition: {
            ease: 'linear',
            duration: NOTIFICATION_DURATION_IN_S,
          },
        }}
        onAnimationComplete={() => removeNotification(id)}
      />
    </div>
  )
}
