import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
};

export type Article = {
  __typename?: 'Article';
  Author: PublicUser;
  MainImage?: Maybe<S3File>;
  Upvotes: Array<PublicUser>;
  averageRating: Scalars['Float']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  hasCurrentUserDownvoted: Scalars['Boolean']['output'];
  hasCurrentUserUpvoted: Scalars['Boolean']['output'];
  numOfRatings: Scalars['Int']['output'];
  publishedAt: Scalars['DateTime']['output'];
  slug: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  totalVotes: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ArticleInput = {
  content: Scalars['String']['input'];
  description: Scalars['String']['input'];
  mainImageKey?: InputMaybe<Scalars['ID']['input']>;
  publishedAt: Scalars['DateTime']['input'];
  slug: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type ArticlesWithPagination = {
  __typename?: 'ArticlesWithPagination';
  articles: Array<Article>;
  pagination: Pagination;
};

export type AuthSession = {
  __typename?: 'AuthSession';
  User: User;
  accessToken: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Author = {
  __typename?: 'Author';
  Image?: Maybe<ImagePreview>;
  company?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type BookmarkInput = {
  articleSlug?: InputMaybe<Scalars['ID']['input']>;
  isBookmarked: Scalars['Boolean']['input'];
  recipeSlug?: InputMaybe<Scalars['ID']['input']>;
};

export type Confirmation = {
  __typename?: 'Confirmation';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  token: Scalars['ID']['output'];
  type: ConfirmationTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};

export enum ConfirmationTypeEnum {
  Onboarding = 'Onboarding',
  ResetPassword = 'ResetPassword',
  SignIn = 'SignIn'
}

export enum FileTypeEnum {
  Document = 'Document',
  Image = 'Image',
  Video = 'Video'
}

export type FoodIngredient = {
  __typename?: 'FoodIngredient';
  FoodIngredientMeasures: Array<FoodIngredientMeasure>;
  FoodIngredientNutrients: Array<FoodIngredientNutrient>;
  category: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  sourceType: Scalars['String']['output'];
};

export type FoodIngredientFilterInput = {
  label?: InputMaybe<Scalars['String']['input']>;
};

export type FoodIngredientInput = {
  FoodIngredientMeasures: Array<FoodIngredientMeasureInput>;
  label: Scalars['String']['input'];
};

export type FoodIngredientMeasure = {
  __typename?: 'FoodIngredientMeasure';
  gramWeight: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

export type FoodIngredientMeasureInput = {
  gramWeight: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  label: Scalars['String']['input'];
};

export type FoodIngredientNutrient = {
  __typename?: 'FoodIngredientNutrient';
  amount?: Maybe<Scalars['Float']['output']>;
  label: Scalars['String']['output'];
  slug: Scalars['ID']['output'];
  unitName: Scalars['String']['output'];
};

export type FoodIngredientsWithPagination = {
  __typename?: 'FoodIngredientsWithPagination';
  foodIngredients: Array<FoodIngredient>;
  pagination: Pagination;
};

export enum GenderEnum {
  Female = 'Female',
  Male = 'Male'
}

export type ImagePreview = {
  __typename?: 'ImagePreview';
  key: Scalars['String']['output'];
  thumbnailSignedUrl?: Maybe<Scalars['String']['output']>;
};


export type ImagePreviewThumbnailSignedUrlArgs = {
  size: ThumbnailSizeEnum;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']['output']>;
  createArticle?: Maybe<Article>;
  createRating?: Maybe<Rating>;
  createRecipe?: Maybe<Recipe>;
  deleteArticle: Scalars['Boolean']['output'];
  deleteConfirmation: Scalars['Boolean']['output'];
  deleteRecipe: Scalars['Boolean']['output'];
  fileUploadS3Presign: S3Payload;
  passwordSignUp: AuthSession;
  requestMagicLink: Scalars['Boolean']['output'];
  requestPasswordReset: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  setBookmark: Scalars['Boolean']['output'];
  signInWithMagicLink: AuthSession;
  signInWithPassword: AuthSession;
  signOut: Scalars['Boolean']['output'];
  signUp: Scalars['Boolean']['output'];
  subscribeToNewsletter?: Maybe<Scalars['Boolean']['output']>;
  updateArticle?: Maybe<Article>;
  updateFoodIngredient: FoodIngredient;
  updatePassword: Scalars['Boolean']['output'];
  updateRating?: Maybe<Rating>;
  updateRecipe?: Maybe<Recipe>;
  updateUser?: Maybe<User>;
  voteArticle?: Maybe<Article>;
};


export type MutationCreateArticleArgs = {
  inputData: ArticleInput;
};


export type MutationCreateRatingArgs = {
  inputData: RatingInput;
};


export type MutationCreateRecipeArgs = {
  inputData: RecipeInput;
};


export type MutationDeleteArticleArgs = {
  slug: Scalars['ID']['input'];
};


export type MutationDeleteConfirmationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRecipeArgs = {
  slug: Scalars['ID']['input'];
};


export type MutationFileUploadS3PresignArgs = {
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  fileSize: Scalars['Int']['input'];
  fileType: FileTypeEnum;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPasswordSignUpArgs = {
  inputData: PasswordSignUpInput;
};


export type MutationRequestMagicLinkArgs = {
  idOrEmail: Scalars['String']['input'];
};


export type MutationRequestPasswordResetArgs = {
  idOrEmail: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSetBookmarkArgs = {
  inputData: BookmarkInput;
};


export type MutationSignInWithMagicLinkArgs = {
  token: Scalars['String']['input'];
};


export type MutationSignInWithPasswordArgs = {
  inputData: SignInWithPasswordInput;
};


export type MutationSignOutArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSignUpArgs = {
  inputData: SignUpInput;
};


export type MutationSubscribeToNewsletterArgs = {
  email: Scalars['String']['input'];
};


export type MutationUpdateArticleArgs = {
  inputData: ArticleInput;
  slug: Scalars['ID']['input'];
};


export type MutationUpdateFoodIngredientArgs = {
  id: Scalars['ID']['input'];
  inputData: FoodIngredientInput;
};


export type MutationUpdatePasswordArgs = {
  inputData: UpdatePasswordInput;
};


export type MutationUpdateRatingArgs = {
  id: Scalars['ID']['input'];
  inputData: RatingInput;
};


export type MutationUpdateRecipeArgs = {
  inputData: RecipeInput;
  slug: Scalars['ID']['input'];
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  inputData: UpdateUserInput;
};


export type MutationVoteArticleArgs = {
  inputData: VoteInput;
  slug: Scalars['ID']['input'];
};

export enum OrderByDirectionEnum {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type Pagination = {
  __typename?: 'Pagination';
  from: Scalars['Int']['output'];
  length: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginationInput = {
  from: Scalars['Int']['input'];
  length: Scalars['Int']['input'];
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderByDirection?: InputMaybe<OrderByDirectionEnum>;
};

export type PasswordSignUpInput = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isSubscribedToNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type PublicUser = {
  __typename?: 'PublicUser';
  UserImage?: Maybe<ImagePreview>;
  company?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']['output']>;
  article?: Maybe<Article>;
  articles: ArticlesWithPagination;
  bookmarkedRecipes: RecipesWithPagination;
  foodIngredient: FoodIngredient;
  foodIngredients: FoodIngredientsWithPagination;
  me?: Maybe<AuthSession>;
  ratings: RatingsWithPagination;
  recipe?: Maybe<Recipe>;
  recipes: RecipesWithPagination;
  user?: Maybe<User>;
  users: UsersWithPagination;
};


export type QueryArticleArgs = {
  slug: Scalars['ID']['input'];
};


export type QueryArticlesArgs = {
  pagination: PaginationInput;
};


export type QueryBookmarkedRecipesArgs = {
  pagination: PaginationInput;
};


export type QueryFoodIngredientArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFoodIngredientsArgs = {
  filterData?: InputMaybe<FoodIngredientFilterInput>;
  pagination: PaginationInput;
};


export type QueryRatingsArgs = {
  articleSlug?: InputMaybe<Scalars['ID']['input']>;
  pagination: PaginationInput;
  recipeSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryRecipeArgs = {
  slug: Scalars['ID']['input'];
};


export type QueryRecipesArgs = {
  pagination: PaginationInput;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  filterData?: InputMaybe<UsersFilterInput>;
  pagination: PaginationInput;
};

export type Rating = {
  __typename?: 'Rating';
  articleSlug?: Maybe<Scalars['ID']['output']>;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parentRatingId?: Maybe<Scalars['ID']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  recipeSlug?: Maybe<Scalars['ID']['output']>;
  replies: Array<Rating>;
  updatedAt: Scalars['DateTime']['output'];
};

export type RatingInput = {
  articleSlug?: InputMaybe<Scalars['ID']['input']>;
  content: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentRatingId?: InputMaybe<Scalars['ID']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  recipeSlug?: InputMaybe<Scalars['ID']['input']>;
};

export type RatingsWithPagination = {
  __typename?: 'RatingsWithPagination';
  pagination: Pagination;
  ratings: Array<Rating>;
};

export type Recipe = {
  __typename?: 'Recipe';
  MainImage?: Maybe<S3File>;
  MetaDataJson: Scalars['String']['output'];
  RecipeDirectionGroups: Array<RecipeDirectionGroup>;
  RecipeExtraTips: Array<RecipeExtraTip>;
  RecipeIngredientGroups: Array<RecipeIngredientGroup>;
  averageRating: Scalars['Float']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  freeFrom: Array<RecipeAllergen>;
  isBookmarked: Scalars['Boolean']['output'];
  numOfRatings: Scalars['Int']['output'];
  publishedAt: Scalars['DateTime']['output'];
  servings: Scalars['Int']['output'];
  slug: Scalars['ID']['output'];
  timesJson: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum RecipeAllergen {
  Celery = 'Celery',
  Crustacean = 'Crustacean',
  Egg = 'Egg',
  Fish = 'Fish',
  Gluten = 'Gluten',
  Lupins = 'Lupins',
  Mustard = 'Mustard',
  Nuts = 'Nuts',
  Peanuts = 'Peanuts',
  Sesame = 'Sesame',
  Soy = 'Soy',
  Sulfites = 'Sulfites'
}

export type RecipeDirection = {
  __typename?: 'RecipeDirection';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RecipeDirectionGroup = {
  __typename?: 'RecipeDirectionGroup';
  RecipeDirections: Array<RecipeDirection>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RecipeDirectionGroupInput = {
  RecipeDirections: Array<RecipeDirectionInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  index: Scalars['Int']['input'];
  label: Scalars['String']['input'];
};

export type RecipeDirectionInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  index: Scalars['Int']['input'];
};

export type RecipeExtraTip = {
  __typename?: 'RecipeExtraTip';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RecipeExtraTipInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  index: Scalars['Int']['input'];
};

export type RecipeIngredient = {
  __typename?: 'RecipeIngredient';
  FoodIngredient: FoodIngredient;
  FoodIngredientMeasure: FoodIngredientMeasure;
  amount: Scalars['Float']['output'];
  contains: Array<RecipeAllergen>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type RecipeIngredientGroup = {
  __typename?: 'RecipeIngredientGroup';
  RecipeIngredients: Array<RecipeIngredient>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RecipeIngredientGroupInput = {
  RecipeIngredients: Array<RecipeIngredientInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  index: Scalars['Int']['input'];
  label: Scalars['String']['input'];
};

export type RecipeIngredientInput = {
  amount: Scalars['Float']['input'];
  contains: Array<RecipeAllergen>;
  foodIngredientId: Scalars['ID']['input'];
  foodIngredientMeasureId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  index: Scalars['Int']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
};

export type RecipeInput = {
  RecipeDirectionGroups: Array<RecipeDirectionGroupInput>;
  RecipeExtraTips: Array<RecipeExtraTipInput>;
  RecipeIngredientGroups: Array<RecipeIngredientGroupInput>;
  content: Scalars['String']['input'];
  description: Scalars['String']['input'];
  freeFrom: Array<RecipeAllergen>;
  mainImageKey?: InputMaybe<Scalars['ID']['input']>;
  publishedAt: Scalars['DateTime']['input'];
  servings: Scalars['Int']['input'];
  slug: Scalars['ID']['input'];
  timesJson: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type RecipesWithPagination = {
  __typename?: 'RecipesWithPagination';
  pagination: Pagination;
  recipes: Array<Recipe>;
};

export type S3File = {
  __typename?: 'S3File';
  Uploader: User;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isPublic: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  signExpiresAt?: Maybe<Scalars['DateTime']['output']>;
  signedUrl?: Maybe<Scalars['String']['output']>;
  size: Scalars['Int']['output'];
  thumbnailSignedUrl?: Maybe<Scalars['String']['output']>;
  type: FileTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};


export type S3FileThumbnailSignedUrlArgs = {
  size: ThumbnailSizeEnum;
};

export type S3Payload = {
  __typename?: 'S3Payload';
  key: Scalars['String']['output'];
  signedRequest: Scalars['String']['output'];
};

export type SignInWithPasswordInput = {
  idOrEmail: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SignUpInput = {
  email: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export enum ThumbnailSizeEnum {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small'
}

export type UpdatePasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  S3FileKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<GenderEnum>;
  isSubscribedToNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  Confirmations: Array<Confirmation>;
  ImageS3File?: Maybe<ImagePreview>;
  bio?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<GenderEnum>;
  hasPassword: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isSubscribedToNewsletter: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  role: UserRoleEnum;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type UserConfirmationsArgs = {
  type?: InputMaybe<ConfirmationTypeEnum>;
};

export type UserImageInput = {
  isPublic: Scalars['Boolean']['input'];
  s3FileKey: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export enum UserRoleEnum {
  SuperAdmin = 'SuperAdmin',
  User = 'User'
}

export type UsersFilterInput = {
  excludeUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UsersWithPagination = {
  __typename?: 'UsersWithPagination';
  pagination: Pagination;
  users: Array<User>;
};

export type VoteInput = {
  type: VoteType;
  value: Scalars['Boolean']['input'];
};

export enum VoteType {
  DownVote = 'DownVote',
  UpVote = 'UpVote'
}

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = T | Promise<T | null> | null;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Article: ResolverTypeWrapper<Partial<Article>>;
  ArticleInput: ResolverTypeWrapper<Partial<ArticleInput>>;
  ArticlesWithPagination: ResolverTypeWrapper<Partial<ArticlesWithPagination>>;
  AuthSession: ResolverTypeWrapper<Partial<AuthSession>>;
  Author: ResolverTypeWrapper<Partial<Author>>;
  BookmarkInput: ResolverTypeWrapper<Partial<BookmarkInput>>;
  Boolean: ResolverTypeWrapper<Partial<Scalars['Boolean']['output']>>;
  Confirmation: ResolverTypeWrapper<Partial<Confirmation>>;
  ConfirmationTypeEnum: ResolverTypeWrapper<Partial<ConfirmationTypeEnum>>;
  DateTime: ResolverTypeWrapper<Partial<Scalars['DateTime']['output']>>;
  FileTypeEnum: ResolverTypeWrapper<Partial<FileTypeEnum>>;
  Float: ResolverTypeWrapper<Partial<Scalars['Float']['output']>>;
  FoodIngredient: ResolverTypeWrapper<Partial<FoodIngredient>>;
  FoodIngredientFilterInput: ResolverTypeWrapper<Partial<FoodIngredientFilterInput>>;
  FoodIngredientInput: ResolverTypeWrapper<Partial<FoodIngredientInput>>;
  FoodIngredientMeasure: ResolverTypeWrapper<Partial<FoodIngredientMeasure>>;
  FoodIngredientMeasureInput: ResolverTypeWrapper<Partial<FoodIngredientMeasureInput>>;
  FoodIngredientNutrient: ResolverTypeWrapper<Partial<FoodIngredientNutrient>>;
  FoodIngredientsWithPagination: ResolverTypeWrapper<Partial<FoodIngredientsWithPagination>>;
  GenderEnum: ResolverTypeWrapper<Partial<GenderEnum>>;
  ID: ResolverTypeWrapper<Partial<Scalars['ID']['output']>>;
  ImagePreview: ResolverTypeWrapper<Partial<ImagePreview>>;
  Int: ResolverTypeWrapper<Partial<Scalars['Int']['output']>>;
  Mutation: ResolverTypeWrapper<{}>;
  OrderByDirectionEnum: ResolverTypeWrapper<Partial<OrderByDirectionEnum>>;
  Pagination: ResolverTypeWrapper<Partial<Pagination>>;
  PaginationInput: ResolverTypeWrapper<Partial<PaginationInput>>;
  PasswordSignUpInput: ResolverTypeWrapper<Partial<PasswordSignUpInput>>;
  PublicUser: ResolverTypeWrapper<Partial<PublicUser>>;
  Query: ResolverTypeWrapper<{}>;
  Rating: ResolverTypeWrapper<Partial<Rating>>;
  RatingInput: ResolverTypeWrapper<Partial<RatingInput>>;
  RatingsWithPagination: ResolverTypeWrapper<Partial<RatingsWithPagination>>;
  Recipe: ResolverTypeWrapper<Partial<Recipe>>;
  RecipeAllergen: ResolverTypeWrapper<Partial<RecipeAllergen>>;
  RecipeDirection: ResolverTypeWrapper<Partial<RecipeDirection>>;
  RecipeDirectionGroup: ResolverTypeWrapper<Partial<RecipeDirectionGroup>>;
  RecipeDirectionGroupInput: ResolverTypeWrapper<Partial<RecipeDirectionGroupInput>>;
  RecipeDirectionInput: ResolverTypeWrapper<Partial<RecipeDirectionInput>>;
  RecipeExtraTip: ResolverTypeWrapper<Partial<RecipeExtraTip>>;
  RecipeExtraTipInput: ResolverTypeWrapper<Partial<RecipeExtraTipInput>>;
  RecipeIngredient: ResolverTypeWrapper<Partial<RecipeIngredient>>;
  RecipeIngredientGroup: ResolverTypeWrapper<Partial<RecipeIngredientGroup>>;
  RecipeIngredientGroupInput: ResolverTypeWrapper<Partial<RecipeIngredientGroupInput>>;
  RecipeIngredientInput: ResolverTypeWrapper<Partial<RecipeIngredientInput>>;
  RecipeInput: ResolverTypeWrapper<Partial<RecipeInput>>;
  RecipesWithPagination: ResolverTypeWrapper<Partial<RecipesWithPagination>>;
  S3File: ResolverTypeWrapper<Partial<S3File>>;
  S3Payload: ResolverTypeWrapper<Partial<S3Payload>>;
  SignInWithPasswordInput: ResolverTypeWrapper<Partial<SignInWithPasswordInput>>;
  SignUpInput: ResolverTypeWrapper<Partial<SignUpInput>>;
  String: ResolverTypeWrapper<Partial<Scalars['String']['output']>>;
  ThumbnailSizeEnum: ResolverTypeWrapper<Partial<ThumbnailSizeEnum>>;
  UpdatePasswordInput: ResolverTypeWrapper<Partial<UpdatePasswordInput>>;
  UpdateUserInput: ResolverTypeWrapper<Partial<UpdateUserInput>>;
  User: ResolverTypeWrapper<Partial<User>>;
  UserImageInput: ResolverTypeWrapper<Partial<UserImageInput>>;
  UserRoleEnum: ResolverTypeWrapper<Partial<UserRoleEnum>>;
  UsersFilterInput: ResolverTypeWrapper<Partial<UsersFilterInput>>;
  UsersWithPagination: ResolverTypeWrapper<Partial<UsersWithPagination>>;
  VoteInput: ResolverTypeWrapper<Partial<VoteInput>>;
  VoteType: ResolverTypeWrapper<Partial<VoteType>>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Article: Partial<Article>;
  ArticleInput: Partial<ArticleInput>;
  ArticlesWithPagination: Partial<ArticlesWithPagination>;
  AuthSession: Partial<AuthSession>;
  Author: Partial<Author>;
  BookmarkInput: Partial<BookmarkInput>;
  Boolean: Partial<Scalars['Boolean']['output']>;
  Confirmation: Partial<Confirmation>;
  DateTime: Partial<Scalars['DateTime']['output']>;
  Float: Partial<Scalars['Float']['output']>;
  FoodIngredient: Partial<FoodIngredient>;
  FoodIngredientFilterInput: Partial<FoodIngredientFilterInput>;
  FoodIngredientInput: Partial<FoodIngredientInput>;
  FoodIngredientMeasure: Partial<FoodIngredientMeasure>;
  FoodIngredientMeasureInput: Partial<FoodIngredientMeasureInput>;
  FoodIngredientNutrient: Partial<FoodIngredientNutrient>;
  FoodIngredientsWithPagination: Partial<FoodIngredientsWithPagination>;
  ID: Partial<Scalars['ID']['output']>;
  ImagePreview: Partial<ImagePreview>;
  Int: Partial<Scalars['Int']['output']>;
  Mutation: {};
  Pagination: Partial<Pagination>;
  PaginationInput: Partial<PaginationInput>;
  PasswordSignUpInput: Partial<PasswordSignUpInput>;
  PublicUser: Partial<PublicUser>;
  Query: {};
  Rating: Partial<Rating>;
  RatingInput: Partial<RatingInput>;
  RatingsWithPagination: Partial<RatingsWithPagination>;
  Recipe: Partial<Recipe>;
  RecipeDirection: Partial<RecipeDirection>;
  RecipeDirectionGroup: Partial<RecipeDirectionGroup>;
  RecipeDirectionGroupInput: Partial<RecipeDirectionGroupInput>;
  RecipeDirectionInput: Partial<RecipeDirectionInput>;
  RecipeExtraTip: Partial<RecipeExtraTip>;
  RecipeExtraTipInput: Partial<RecipeExtraTipInput>;
  RecipeIngredient: Partial<RecipeIngredient>;
  RecipeIngredientGroup: Partial<RecipeIngredientGroup>;
  RecipeIngredientGroupInput: Partial<RecipeIngredientGroupInput>;
  RecipeIngredientInput: Partial<RecipeIngredientInput>;
  RecipeInput: Partial<RecipeInput>;
  RecipesWithPagination: Partial<RecipesWithPagination>;
  S3File: Partial<S3File>;
  S3Payload: Partial<S3Payload>;
  SignInWithPasswordInput: Partial<SignInWithPasswordInput>;
  SignUpInput: Partial<SignUpInput>;
  String: Partial<Scalars['String']['output']>;
  UpdatePasswordInput: Partial<UpdatePasswordInput>;
  UpdateUserInput: Partial<UpdateUserInput>;
  User: Partial<User>;
  UserImageInput: Partial<UserImageInput>;
  UsersFilterInput: Partial<UsersFilterInput>;
  UsersWithPagination: Partial<UsersWithPagination>;
  VoteInput: Partial<VoteInput>;
}>;

export type ArticleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Article'] = ResolversParentTypes['Article']> = ResolversObject<{
  Author?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  MainImage?: Resolver<Maybe<ResolversTypes['S3File']>, ParentType, ContextType>;
  Upvotes?: Resolver<Array<ResolversTypes['PublicUser']>, ParentType, ContextType>;
  averageRating?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasCurrentUserDownvoted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasCurrentUserUpvoted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  numOfRatings?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  publishedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalVotes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ArticlesWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesWithPagination'] = ResolversParentTypes['ArticlesWithPagination']> = ResolversObject<{
  articles?: Resolver<Array<ResolversTypes['Article']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AuthSessionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthSession'] = ResolversParentTypes['AuthSession']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AuthorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Author'] = ResolversParentTypes['Author']> = ResolversObject<{
  Image?: Resolver<Maybe<ResolversTypes['ImagePreview']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConfirmationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Confirmation'] = ResolversParentTypes['Confirmation']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ConfirmationTypeEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type FoodIngredientResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodIngredient'] = ResolversParentTypes['FoodIngredient']> = ResolversObject<{
  FoodIngredientMeasures?: Resolver<Array<ResolversTypes['FoodIngredientMeasure']>, ParentType, ContextType>;
  FoodIngredientNutrients?: Resolver<Array<ResolversTypes['FoodIngredientNutrient']>, ParentType, ContextType>;
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sourceType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FoodIngredientMeasureResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodIngredientMeasure'] = ResolversParentTypes['FoodIngredientMeasure']> = ResolversObject<{
  gramWeight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FoodIngredientNutrientResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodIngredientNutrient'] = ResolversParentTypes['FoodIngredientNutrient']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  unitName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FoodIngredientsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodIngredientsWithPagination'] = ResolversParentTypes['FoodIngredientsWithPagination']> = ResolversObject<{
  foodIngredients?: Resolver<Array<ResolversTypes['FoodIngredient']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ImagePreviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImagePreview'] = ResolversParentTypes['ImagePreview']> = ResolversObject<{
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbnailSignedUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<ImagePreviewThumbnailSignedUrlArgs, 'size'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = ResolversObject<{
  _?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  createArticle?: Resolver<Maybe<ResolversTypes['Article']>, ParentType, ContextType, RequireFields<MutationCreateArticleArgs, 'inputData'>>;
  createRating?: Resolver<Maybe<ResolversTypes['Rating']>, ParentType, ContextType, RequireFields<MutationCreateRatingArgs, 'inputData'>>;
  createRecipe?: Resolver<Maybe<ResolversTypes['Recipe']>, ParentType, ContextType, RequireFields<MutationCreateRecipeArgs, 'inputData'>>;
  deleteArticle?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteArticleArgs, 'slug'>>;
  deleteConfirmation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteConfirmationArgs, 'id'>>;
  deleteRecipe?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteRecipeArgs, 'slug'>>;
  fileUploadS3Presign?: Resolver<ResolversTypes['S3Payload'], ParentType, ContextType, RequireFields<MutationFileUploadS3PresignArgs, 'contentType' | 'fileName' | 'fileSize' | 'fileType'>>;
  passwordSignUp?: Resolver<ResolversTypes['AuthSession'], ParentType, ContextType, RequireFields<MutationPasswordSignUpArgs, 'inputData'>>;
  requestMagicLink?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRequestMagicLinkArgs, 'idOrEmail'>>;
  requestPasswordReset?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRequestPasswordResetArgs, 'idOrEmail'>>;
  resetPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationResetPasswordArgs, 'newPassword' | 'token'>>;
  setBookmark?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetBookmarkArgs, 'inputData'>>;
  signInWithMagicLink?: Resolver<ResolversTypes['AuthSession'], ParentType, ContextType, RequireFields<MutationSignInWithMagicLinkArgs, 'token'>>;
  signInWithPassword?: Resolver<ResolversTypes['AuthSession'], ParentType, ContextType, RequireFields<MutationSignInWithPasswordArgs, 'inputData'>>;
  signOut?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, Partial<MutationSignOutArgs>>;
  signUp?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSignUpArgs, 'inputData'>>;
  subscribeToNewsletter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSubscribeToNewsletterArgs, 'email'>>;
  updateArticle?: Resolver<Maybe<ResolversTypes['Article']>, ParentType, ContextType, RequireFields<MutationUpdateArticleArgs, 'inputData' | 'slug'>>;
  updateFoodIngredient?: Resolver<ResolversTypes['FoodIngredient'], ParentType, ContextType, RequireFields<MutationUpdateFoodIngredientArgs, 'id' | 'inputData'>>;
  updatePassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePasswordArgs, 'inputData'>>;
  updateRating?: Resolver<Maybe<ResolversTypes['Rating']>, ParentType, ContextType, RequireFields<MutationUpdateRatingArgs, 'id' | 'inputData'>>;
  updateRecipe?: Resolver<Maybe<ResolversTypes['Recipe']>, ParentType, ContextType, RequireFields<MutationUpdateRecipeArgs, 'inputData' | 'slug'>>;
  updateUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'id' | 'inputData'>>;
  voteArticle?: Resolver<Maybe<ResolversTypes['Article']>, ParentType, ContextType, RequireFields<MutationVoteArticleArgs, 'inputData' | 'slug'>>;
}>;

export type PaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Pagination'] = ResolversParentTypes['Pagination']> = ResolversObject<{
  from?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PublicUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicUser'] = ResolversParentTypes['PublicUser']> = ResolversObject<{
  UserImage?: Resolver<Maybe<ResolversTypes['ImagePreview']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  _?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  article?: Resolver<Maybe<ResolversTypes['Article']>, ParentType, ContextType, RequireFields<QueryArticleArgs, 'slug'>>;
  articles?: Resolver<ResolversTypes['ArticlesWithPagination'], ParentType, ContextType, RequireFields<QueryArticlesArgs, 'pagination'>>;
  bookmarkedRecipes?: Resolver<ResolversTypes['RecipesWithPagination'], ParentType, ContextType, RequireFields<QueryBookmarkedRecipesArgs, 'pagination'>>;
  foodIngredient?: Resolver<ResolversTypes['FoodIngredient'], ParentType, ContextType, RequireFields<QueryFoodIngredientArgs, 'id'>>;
  foodIngredients?: Resolver<ResolversTypes['FoodIngredientsWithPagination'], ParentType, ContextType, RequireFields<QueryFoodIngredientsArgs, 'pagination'>>;
  me?: Resolver<Maybe<ResolversTypes['AuthSession']>, ParentType, ContextType>;
  ratings?: Resolver<ResolversTypes['RatingsWithPagination'], ParentType, ContextType, RequireFields<QueryRatingsArgs, 'pagination'>>;
  recipe?: Resolver<Maybe<ResolversTypes['Recipe']>, ParentType, ContextType, RequireFields<QueryRecipeArgs, 'slug'>>;
  recipes?: Resolver<ResolversTypes['RecipesWithPagination'], ParentType, ContextType, RequireFields<QueryRecipesArgs, 'pagination'>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, 'id'>>;
  users?: Resolver<ResolversTypes['UsersWithPagination'], ParentType, ContextType, RequireFields<QueryUsersArgs, 'pagination'>>;
}>;

export type RatingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Rating'] = ResolversParentTypes['Rating']> = ResolversObject<{
  articleSlug?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parentRatingId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recipeSlug?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  replies?: Resolver<Array<ResolversTypes['Rating']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RatingsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['RatingsWithPagination'] = ResolversParentTypes['RatingsWithPagination']> = ResolversObject<{
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  ratings?: Resolver<Array<ResolversTypes['Rating']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecipeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Recipe'] = ResolversParentTypes['Recipe']> = ResolversObject<{
  MainImage?: Resolver<Maybe<ResolversTypes['S3File']>, ParentType, ContextType>;
  MetaDataJson?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  RecipeDirectionGroups?: Resolver<Array<ResolversTypes['RecipeDirectionGroup']>, ParentType, ContextType>;
  RecipeExtraTips?: Resolver<Array<ResolversTypes['RecipeExtraTip']>, ParentType, ContextType>;
  RecipeIngredientGroups?: Resolver<Array<ResolversTypes['RecipeIngredientGroup']>, ParentType, ContextType>;
  averageRating?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  freeFrom?: Resolver<Array<ResolversTypes['RecipeAllergen']>, ParentType, ContextType>;
  isBookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  numOfRatings?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  publishedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  servings?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  timesJson?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecipeDirectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecipeDirection'] = ResolversParentTypes['RecipeDirection']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecipeDirectionGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecipeDirectionGroup'] = ResolversParentTypes['RecipeDirectionGroup']> = ResolversObject<{
  RecipeDirections?: Resolver<Array<ResolversTypes['RecipeDirection']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecipeExtraTipResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecipeExtraTip'] = ResolversParentTypes['RecipeExtraTip']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecipeIngredientResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecipeIngredient'] = ResolversParentTypes['RecipeIngredient']> = ResolversObject<{
  FoodIngredient?: Resolver<ResolversTypes['FoodIngredient'], ParentType, ContextType>;
  FoodIngredientMeasure?: Resolver<ResolversTypes['FoodIngredientMeasure'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  contains?: Resolver<Array<ResolversTypes['RecipeAllergen']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecipeIngredientGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecipeIngredientGroup'] = ResolversParentTypes['RecipeIngredientGroup']> = ResolversObject<{
  RecipeIngredients?: Resolver<Array<ResolversTypes['RecipeIngredient']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecipesWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecipesWithPagination'] = ResolversParentTypes['RecipesWithPagination']> = ResolversObject<{
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  recipes?: Resolver<Array<ResolversTypes['Recipe']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type S3FileResolvers<ContextType = any, ParentType extends ResolversParentTypes['S3File'] = ResolversParentTypes['S3File']> = ResolversObject<{
  Uploader?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isPublic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signExpiresAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  signedUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  thumbnailSignedUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<S3FileThumbnailSignedUrlArgs, 'size'>>;
  type?: Resolver<ResolversTypes['FileTypeEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type S3PayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['S3Payload'] = ResolversParentTypes['S3Payload']> = ResolversObject<{
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signedRequest?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  Confirmations?: Resolver<Array<ResolversTypes['Confirmation']>, ParentType, ContextType, Partial<UserConfirmationsArgs>>;
  ImageS3File?: Resolver<Maybe<ResolversTypes['ImagePreview']>, ParentType, ContextType>;
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['GenderEnum']>, ParentType, ContextType>;
  hasPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isSubscribedToNewsletter?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRoleEnum'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UsersWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersWithPagination'] = ResolversParentTypes['UsersWithPagination']> = ResolversObject<{
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  Article?: ArticleResolvers<ContextType>;
  ArticlesWithPagination?: ArticlesWithPaginationResolvers<ContextType>;
  AuthSession?: AuthSessionResolvers<ContextType>;
  Author?: AuthorResolvers<ContextType>;
  Confirmation?: ConfirmationResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  FoodIngredient?: FoodIngredientResolvers<ContextType>;
  FoodIngredientMeasure?: FoodIngredientMeasureResolvers<ContextType>;
  FoodIngredientNutrient?: FoodIngredientNutrientResolvers<ContextType>;
  FoodIngredientsWithPagination?: FoodIngredientsWithPaginationResolvers<ContextType>;
  ImagePreview?: ImagePreviewResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Pagination?: PaginationResolvers<ContextType>;
  PublicUser?: PublicUserResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Rating?: RatingResolvers<ContextType>;
  RatingsWithPagination?: RatingsWithPaginationResolvers<ContextType>;
  Recipe?: RecipeResolvers<ContextType>;
  RecipeDirection?: RecipeDirectionResolvers<ContextType>;
  RecipeDirectionGroup?: RecipeDirectionGroupResolvers<ContextType>;
  RecipeExtraTip?: RecipeExtraTipResolvers<ContextType>;
  RecipeIngredient?: RecipeIngredientResolvers<ContextType>;
  RecipeIngredientGroup?: RecipeIngredientGroupResolvers<ContextType>;
  RecipesWithPagination?: RecipesWithPaginationResolvers<ContextType>;
  S3File?: S3FileResolvers<ContextType>;
  S3Payload?: S3PayloadResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UsersWithPagination?: UsersWithPaginationResolvers<ContextType>;
}>;


export type ArticleQueryVariables = Exact<{
  slug: Scalars['ID']['input'];
}>;


export type ArticleQuery = { __typename?: 'Query', article?: { __typename?: 'Article', slug: string, createdAt: string, updatedAt: string, publishedAt: string, title: string, description: string, content: string, totalVotes: number, hasCurrentUserUpvoted: boolean, hasCurrentUserDownvoted: boolean, Upvotes: Array<{ __typename?: 'PublicUser', id: string, firstName?: string | null, lastName?: string | null, title?: string | null, company?: string | null, UserImage?: { __typename?: 'ImagePreview', key: string, thumbnailSignedUrl?: string | null } | null }>, Author: { __typename?: 'PublicUser', id: string, firstName?: string | null, lastName?: string | null, title?: string | null, company?: string | null, UserImage?: { __typename?: 'ImagePreview', key: string, thumbnailSignedUrl?: string | null } | null }, MainImage?: { __typename?: 'S3File', key: string, thumbnailSignedUrl?: string | null } | null } | null };

export type ArticlesQueryVariables = Exact<{
  pagination: PaginationInput;
}>;


export type ArticlesQuery = { __typename?: 'Query', articles: { __typename?: 'ArticlesWithPagination', articles: Array<{ __typename?: 'Article', slug: string, createdAt: string, updatedAt: string, publishedAt: string, title: string, description: string, content: string, averageRating: number, numOfRatings: number, totalVotes: number, hasCurrentUserUpvoted: boolean, hasCurrentUserDownvoted: boolean, Author: { __typename?: 'PublicUser', id: string, firstName?: string | null, lastName?: string | null, title?: string | null, company?: string | null, UserImage?: { __typename?: 'ImagePreview', key: string, thumbnailSignedUrl?: string | null } | null }, Upvotes: Array<{ __typename?: 'PublicUser', id: string, firstName?: string | null, lastName?: string | null, title?: string | null, company?: string | null, UserImage?: { __typename?: 'ImagePreview', key: string, thumbnailSignedUrl?: string | null } | null }>, MainImage?: { __typename?: 'S3File', key: string, thumbnailSignedUrl?: string | null } | null }>, pagination: { __typename?: 'Pagination', from: number, length: number, total: number } } };

export type CreateArticleMutationVariables = Exact<{
  inputData: ArticleInput;
}>;


export type CreateArticleMutation = { __typename?: 'Mutation', createArticle?: { __typename?: 'Article', slug: string } | null };

export type UpdateArticleMutationVariables = Exact<{
  inputData: ArticleInput;
  slug: Scalars['ID']['input'];
}>;


export type UpdateArticleMutation = { __typename?: 'Mutation', updateArticle?: { __typename?: 'Article', slug: string } | null };

export type DeletePostMutationVariables = Exact<{
  slug: Scalars['ID']['input'];
}>;


export type DeletePostMutation = { __typename?: 'Mutation', deleteArticle: boolean };

export type VoteArticleMutationVariables = Exact<{
  inputData: VoteInput;
  slug: Scalars['ID']['input'];
}>;


export type VoteArticleMutation = { __typename?: 'Mutation', voteArticle?: { __typename?: 'Article', slug: string } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'AuthSession', accessToken: string, User: { __typename?: 'User', id: string, email: string, role: UserRoleEnum, hasPassword: boolean, firstName?: string | null, lastName?: string | null, gender?: GenderEnum | null, company?: string | null, title?: string | null, city?: string | null, country?: string | null, bio?: string | null, isSubscribedToNewsletter: boolean, ImageS3File?: { __typename?: 'ImagePreview', key: string, thumbnailSignedUrl?: string | null } | null, Confirmations: Array<{ __typename?: 'Confirmation', id: string, type: ConfirmationTypeEnum }> } } | null };

export type SignOutMutationVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type SignOutMutation = { __typename?: 'Mutation', signOut: boolean };

export type SignUpMutationVariables = Exact<{
  inputData: SignUpInput;
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp: boolean };

export type SignInWithPasswordMutationVariables = Exact<{
  inputData: SignInWithPasswordInput;
}>;


export type SignInWithPasswordMutation = { __typename?: 'Mutation', signInWithPassword: { __typename?: 'AuthSession', accessToken: string, User: { __typename?: 'User', id: string, email: string, role: UserRoleEnum, hasPassword: boolean, firstName?: string | null, lastName?: string | null, gender?: GenderEnum | null, company?: string | null, title?: string | null, city?: string | null, country?: string | null, bio?: string | null, isSubscribedToNewsletter: boolean, ImageS3File?: { __typename?: 'ImagePreview', key: string, thumbnailSignedUrl?: string | null } | null, Confirmations: Array<{ __typename?: 'Confirmation', id: string, type: ConfirmationTypeEnum }> } } };

export type SignInWithMagicLinkMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type SignInWithMagicLinkMutation = { __typename?: 'Mutation', signInWithMagicLink: { __typename?: 'AuthSession', accessToken: string, User: { __typename?: 'User', id: string, email: string, role: UserRoleEnum, hasPassword: boolean, firstName?: string | null, lastName?: string | null, gender?: GenderEnum | null, company?: string | null, title?: string | null, city?: string | null, country?: string | null, bio?: string | null, isSubscribedToNewsletter: boolean, ImageS3File?: { __typename?: 'ImagePreview', key: string, thumbnailSignedUrl?: string | null } | null, Confirmations: Array<{ __typename?: 'Confirmation', id: string, type: ConfirmationTypeEnum }> } } };

export type RequestMagicLinkMutationVariables = Exact<{
  idOrEmail: Scalars['String']['input'];
}>;


export type RequestMagicLinkMutation = { __typename?: 'Mutation', requestMagicLink: boolean };

export type UpdatePasswordMutationVariables = Exact<{
  inputData: UpdatePasswordInput;
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword: boolean };

export type RequestPasswordResetMutationVariables = Exact<{
  idOrEmail: Scalars['String']['input'];
}>;


export type RequestPasswordResetMutation = { __typename?: 'Mutation', requestPasswordReset: boolean };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type PasswordSignUpMutationVariables = Exact<{
  inputData: PasswordSignUpInput;
}>;


export type PasswordSignUpMutation = { __typename?: 'Mutation', passwordSignUp: { __typename?: 'AuthSession', accessToken: string, User: { __typename?: 'User', id: string, email: string, role: UserRoleEnum, hasPassword: boolean, firstName?: string | null, lastName?: string | null, gender?: GenderEnum | null, company?: string | null, title?: string | null, city?: string | null, country?: string | null, bio?: string | null, isSubscribedToNewsletter: boolean, ImageS3File?: { __typename?: 'ImagePreview', key: string, thumbnailSignedUrl?: string | null } | null, Confirmations: Array<{ __typename?: 'Confirmation', id: string, type: ConfirmationTypeEnum }> } } };

export type SetBookmarkMutationVariables = Exact<{
  inputData: BookmarkInput;
}>;


export type SetBookmarkMutation = { __typename?: 'Mutation', setBookmark: boolean };

export type DeleteConfirmationMutationVariables = Exact<{
  deleteConfirmationId: Scalars['ID']['input'];
}>;


export type DeleteConfirmationMutation = { __typename?: 'Mutation', deleteConfirmation: boolean };

export type FoodIngredientFragmentFragment = { __typename?: 'FoodIngredient', id: string, label: string, category: string, sourceType: string, FoodIngredientNutrients: Array<{ __typename?: 'FoodIngredientNutrient', slug: string, label: string, unitName: string, amount?: number | null }>, FoodIngredientMeasures: Array<{ __typename?: 'FoodIngredientMeasure', id: string, gramWeight: number, label: string }> };

export type FoodIngredientQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FoodIngredientQuery = { __typename?: 'Query', foodIngredient: { __typename?: 'FoodIngredient', id: string, label: string, category: string, sourceType: string, FoodIngredientNutrients: Array<{ __typename?: 'FoodIngredientNutrient', slug: string, label: string, unitName: string, amount?: number | null }>, FoodIngredientMeasures: Array<{ __typename?: 'FoodIngredientMeasure', id: string, gramWeight: number, label: string }> } };

export type FoodIngredientsQueryVariables = Exact<{
  pagination: PaginationInput;
  filterData?: InputMaybe<FoodIngredientFilterInput>;
}>;


export type FoodIngredientsQuery = { __typename?: 'Query', foodIngredients: { __typename?: 'FoodIngredientsWithPagination', foodIngredients: Array<{ __typename?: 'FoodIngredient', id: string, label: string, category: string, sourceType: string, FoodIngredientNutrients: Array<{ __typename?: 'FoodIngredientNutrient', slug: string, label: string, unitName: string, amount?: number | null }>, FoodIngredientMeasures: Array<{ __typename?: 'FoodIngredientMeasure', id: string, gramWeight: number, label: string }> }>, pagination: { __typename?: 'Pagination', from: number, length: number, total: number } } };

export type UpdateFoodIngredientMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  inputData: FoodIngredientInput;
}>;


export type UpdateFoodIngredientMutation = { __typename?: 'Mutation', updateFoodIngredient: { __typename?: 'FoodIngredient', id: string, label: string, category: string, sourceType: string, FoodIngredientNutrients: Array<{ __typename?: 'FoodIngredientNutrient', slug: string, label: string, unitName: string, amount?: number | null }>, FoodIngredientMeasures: Array<{ __typename?: 'FoodIngredientMeasure', id: string, gramWeight: number, label: string }> } };

export type SubscribeToNewsletterMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type SubscribeToNewsletterMutation = { __typename?: 'Mutation', subscribeToNewsletter?: boolean | null };

export type RatingFragmentFragment = { __typename?: 'Rating', id: string, parentRatingId?: string | null, articleSlug?: string | null, recipeSlug?: string | null, createdAt: string, updatedAt: string, rating?: number | null, content: string, name?: string | null };

export type RatingsQueryVariables = Exact<{
  recipeSlug?: InputMaybe<Scalars['ID']['input']>;
  articleSlug?: InputMaybe<Scalars['ID']['input']>;
  pagination: PaginationInput;
}>;


export type RatingsQuery = { __typename?: 'Query', ratings: { __typename?: 'RatingsWithPagination', ratings: Array<{ __typename?: 'Rating', id: string, parentRatingId?: string | null, articleSlug?: string | null, recipeSlug?: string | null, createdAt: string, updatedAt: string, rating?: number | null, content: string, name?: string | null, replies: Array<{ __typename?: 'Rating', id: string, parentRatingId?: string | null, articleSlug?: string | null, recipeSlug?: string | null, createdAt: string, updatedAt: string, rating?: number | null, content: string, name?: string | null }> }>, pagination: { __typename?: 'Pagination', from: number, length: number, total: number } } };

export type CreateRatingMutationVariables = Exact<{
  inputData: RatingInput;
}>;


export type CreateRatingMutation = { __typename?: 'Mutation', createRating?: { __typename?: 'Rating', id: string, parentRatingId?: string | null, articleSlug?: string | null, recipeSlug?: string | null, createdAt: string, updatedAt: string, rating?: number | null, content: string, name?: string | null } | null };

export type RecipeFragmentFragment = { __typename?: 'Recipe', slug: string, createdAt: string, updatedAt: string, publishedAt: string, title: string, description: string, servings: number, timesJson: string, averageRating: number, numOfRatings: number, MetaDataJson: string, isBookmarked: boolean, freeFrom: Array<RecipeAllergen>, RecipeDirectionGroups: Array<{ __typename?: 'RecipeDirectionGroup', id: string, index: number, label: string, RecipeDirections: Array<{ __typename?: 'RecipeDirection', id: string, index: number, description: string }> }>, RecipeIngredientGroups: Array<{ __typename?: 'RecipeIngredientGroup', id: string, index: number, label: string, RecipeIngredients: Array<{ __typename?: 'RecipeIngredient', id: string, index: number, label?: string | null, amount: number, contains: Array<RecipeAllergen>, FoodIngredient: { __typename?: 'FoodIngredient', id: string, label: string, FoodIngredientMeasures: Array<{ __typename?: 'FoodIngredientMeasure', id: string, gramWeight: number, label: string }>, FoodIngredientNutrients: Array<{ __typename?: 'FoodIngredientNutrient', slug: string, amount?: number | null, label: string, unitName: string }> }, FoodIngredientMeasure: { __typename?: 'FoodIngredientMeasure', id: string, gramWeight: number, label: string } }> }>, MainImage?: { __typename?: 'S3File', key: string, thumbnailSignedUrl?: string | null } | null, RecipeExtraTips: Array<{ __typename?: 'RecipeExtraTip', id: string, index: number, description: string }> };

export type RecipeQueryVariables = Exact<{
  slug: Scalars['ID']['input'];
}>;


export type RecipeQuery = { __typename?: 'Query', recipe?: { __typename?: 'Recipe', content: string, slug: string, createdAt: string, updatedAt: string, publishedAt: string, title: string, description: string, servings: number, timesJson: string, averageRating: number, numOfRatings: number, MetaDataJson: string, isBookmarked: boolean, freeFrom: Array<RecipeAllergen>, RecipeDirectionGroups: Array<{ __typename?: 'RecipeDirectionGroup', id: string, index: number, label: string, RecipeDirections: Array<{ __typename?: 'RecipeDirection', id: string, index: number, description: string }> }>, RecipeIngredientGroups: Array<{ __typename?: 'RecipeIngredientGroup', id: string, index: number, label: string, RecipeIngredients: Array<{ __typename?: 'RecipeIngredient', id: string, index: number, label?: string | null, amount: number, contains: Array<RecipeAllergen>, FoodIngredient: { __typename?: 'FoodIngredient', id: string, label: string, FoodIngredientMeasures: Array<{ __typename?: 'FoodIngredientMeasure', id: string, gramWeight: number, label: string }>, FoodIngredientNutrients: Array<{ __typename?: 'FoodIngredientNutrient', slug: string, amount?: number | null, label: string, unitName: string }> }, FoodIngredientMeasure: { __typename?: 'FoodIngredientMeasure', id: string, gramWeight: number, label: string } }> }>, MainImage?: { __typename?: 'S3File', key: string, thumbnailSignedUrl?: string | null } | null, RecipeExtraTips: Array<{ __typename?: 'RecipeExtraTip', id: string, index: number, description: string }> } | null };

export type RecipesQueryVariables = Exact<{
  pagination: PaginationInput;
}>;


export type RecipesQuery = { __typename?: 'Query', recipes: { __typename?: 'RecipesWithPagination', recipes: Array<{ __typename?: 'Recipe', slug: string, createdAt: string, updatedAt: string, publishedAt: string, title: string, description: string, servings: number, timesJson: string, averageRating: number, numOfRatings: number, MetaDataJson: string, isBookmarked: boolean, freeFrom: Array<RecipeAllergen>, RecipeDirectionGroups: Array<{ __typename?: 'RecipeDirectionGroup', id: string, index: number, label: string, RecipeDirections: Array<{ __typename?: 'RecipeDirection', id: string, index: number, description: string }> }>, RecipeIngredientGroups: Array<{ __typename?: 'RecipeIngredientGroup', id: string, index: number, label: string, RecipeIngredients: Array<{ __typename?: 'RecipeIngredient', id: string, index: number, label?: string | null, amount: number, contains: Array<RecipeAllergen>, FoodIngredient: { __typename?: 'FoodIngredient', id: string, label: string, FoodIngredientMeasures: Array<{ __typename?: 'FoodIngredientMeasure', id: string, gramWeight: number, label: string }>, FoodIngredientNutrients: Array<{ __typename?: 'FoodIngredientNutrient', slug: string, amount?: number | null, label: string, unitName: string }> }, FoodIngredientMeasure: { __typename?: 'FoodIngredientMeasure', id: string, gramWeight: number, label: string } }> }>, MainImage?: { __typename?: 'S3File', key: string, thumbnailSignedUrl?: string | null } | null, RecipeExtraTips: Array<{ __typename?: 'RecipeExtraTip', id: string, index: number, description: string }> }>, pagination: { __typename?: 'Pagination', from: number, length: number, total: number } } };

export type BookmarkedRecipesQueryVariables = Exact<{
  pagination: PaginationInput;
}>;


export type BookmarkedRecipesQuery = { __typename?: 'Query', bookmarkedRecipes: { __typename?: 'RecipesWithPagination', recipes: Array<{ __typename?: 'Recipe', slug: string, createdAt: string, updatedAt: string, publishedAt: string, title: string, description: string, servings: number, timesJson: string, averageRating: number, numOfRatings: number, MetaDataJson: string, isBookmarked: boolean, freeFrom: Array<RecipeAllergen>, RecipeDirectionGroups: Array<{ __typename?: 'RecipeDirectionGroup', id: string, index: number, label: string, RecipeDirections: Array<{ __typename?: 'RecipeDirection', id: string, index: number, description: string }> }>, RecipeIngredientGroups: Array<{ __typename?: 'RecipeIngredientGroup', id: string, index: number, label: string, RecipeIngredients: Array<{ __typename?: 'RecipeIngredient', id: string, index: number, label?: string | null, amount: number, contains: Array<RecipeAllergen>, FoodIngredient: { __typename?: 'FoodIngredient', id: string, label: string, FoodIngredientMeasures: Array<{ __typename?: 'FoodIngredientMeasure', id: string, gramWeight: number, label: string }>, FoodIngredientNutrients: Array<{ __typename?: 'FoodIngredientNutrient', slug: string, amount?: number | null, label: string, unitName: string }> }, FoodIngredientMeasure: { __typename?: 'FoodIngredientMeasure', id: string, gramWeight: number, label: string } }> }>, MainImage?: { __typename?: 'S3File', key: string, thumbnailSignedUrl?: string | null } | null, RecipeExtraTips: Array<{ __typename?: 'RecipeExtraTip', id: string, index: number, description: string }> }>, pagination: { __typename?: 'Pagination', from: number, length: number, total: number } } };

export type CreateRecipeMutationVariables = Exact<{
  inputData: RecipeInput;
}>;


export type CreateRecipeMutation = { __typename?: 'Mutation', createRecipe?: { __typename?: 'Recipe', slug: string, createdAt: string, updatedAt: string, publishedAt: string, title: string, description: string, servings: number, timesJson: string, averageRating: number, numOfRatings: number, MetaDataJson: string, isBookmarked: boolean, freeFrom: Array<RecipeAllergen>, RecipeDirectionGroups: Array<{ __typename?: 'RecipeDirectionGroup', id: string, index: number, label: string, RecipeDirections: Array<{ __typename?: 'RecipeDirection', id: string, index: number, description: string }> }>, RecipeIngredientGroups: Array<{ __typename?: 'RecipeIngredientGroup', id: string, index: number, label: string, RecipeIngredients: Array<{ __typename?: 'RecipeIngredient', id: string, index: number, label?: string | null, amount: number, contains: Array<RecipeAllergen>, FoodIngredient: { __typename?: 'FoodIngredient', id: string, label: string, FoodIngredientMeasures: Array<{ __typename?: 'FoodIngredientMeasure', id: string, gramWeight: number, label: string }>, FoodIngredientNutrients: Array<{ __typename?: 'FoodIngredientNutrient', slug: string, amount?: number | null, label: string, unitName: string }> }, FoodIngredientMeasure: { __typename?: 'FoodIngredientMeasure', id: string, gramWeight: number, label: string } }> }>, MainImage?: { __typename?: 'S3File', key: string, thumbnailSignedUrl?: string | null } | null, RecipeExtraTips: Array<{ __typename?: 'RecipeExtraTip', id: string, index: number, description: string }> } | null };

export type UpdateRecipeMutationVariables = Exact<{
  inputData: RecipeInput;
  slug: Scalars['ID']['input'];
}>;


export type UpdateRecipeMutation = { __typename?: 'Mutation', updateRecipe?: { __typename?: 'Recipe', slug: string, createdAt: string, updatedAt: string, publishedAt: string, title: string, description: string, servings: number, timesJson: string, averageRating: number, numOfRatings: number, MetaDataJson: string, isBookmarked: boolean, freeFrom: Array<RecipeAllergen>, RecipeDirectionGroups: Array<{ __typename?: 'RecipeDirectionGroup', id: string, index: number, label: string, RecipeDirections: Array<{ __typename?: 'RecipeDirection', id: string, index: number, description: string }> }>, RecipeIngredientGroups: Array<{ __typename?: 'RecipeIngredientGroup', id: string, index: number, label: string, RecipeIngredients: Array<{ __typename?: 'RecipeIngredient', id: string, index: number, label?: string | null, amount: number, contains: Array<RecipeAllergen>, FoodIngredient: { __typename?: 'FoodIngredient', id: string, label: string, FoodIngredientMeasures: Array<{ __typename?: 'FoodIngredientMeasure', id: string, gramWeight: number, label: string }>, FoodIngredientNutrients: Array<{ __typename?: 'FoodIngredientNutrient', slug: string, amount?: number | null, label: string, unitName: string }> }, FoodIngredientMeasure: { __typename?: 'FoodIngredientMeasure', id: string, gramWeight: number, label: string } }> }>, MainImage?: { __typename?: 'S3File', key: string, thumbnailSignedUrl?: string | null } | null, RecipeExtraTips: Array<{ __typename?: 'RecipeExtraTip', id: string, index: number, description: string }> } | null };

export type DeleteRecipeMutationVariables = Exact<{
  slug: Scalars['ID']['input'];
}>;


export type DeleteRecipeMutation = { __typename?: 'Mutation', deleteRecipe: boolean };

export type FileUploadS3PresignMutationVariables = Exact<{
  fileName: Scalars['String']['input'];
  fileType: FileTypeEnum;
  contentType: Scalars['String']['input'];
  fileSize: Scalars['Int']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type FileUploadS3PresignMutation = { __typename?: 'Mutation', fileUploadS3Presign: { __typename?: 'S3Payload', signedRequest: string, key: string } };

export type PublicUserFragmentFragment = { __typename?: 'PublicUser', id: string, firstName?: string | null, lastName?: string | null, title?: string | null, company?: string | null, UserImage?: { __typename?: 'ImagePreview', key: string, thumbnailSignedUrl?: string | null } | null };

export type UserFragmentFragment = { __typename?: 'User', id: string, email: string, role: UserRoleEnum, hasPassword: boolean, firstName?: string | null, lastName?: string | null, gender?: GenderEnum | null, company?: string | null, title?: string | null, city?: string | null, country?: string | null, bio?: string | null, isSubscribedToNewsletter: boolean, ImageS3File?: { __typename?: 'ImagePreview', key: string, thumbnailSignedUrl?: string | null } | null, Confirmations: Array<{ __typename?: 'Confirmation', id: string, type: ConfirmationTypeEnum }> };

export type UserQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, email: string, role: UserRoleEnum, hasPassword: boolean, firstName?: string | null, lastName?: string | null, gender?: GenderEnum | null, company?: string | null, title?: string | null, city?: string | null, country?: string | null, bio?: string | null, isSubscribedToNewsletter: boolean, ImageS3File?: { __typename?: 'ImagePreview', key: string, thumbnailSignedUrl?: string | null } | null, Confirmations: Array<{ __typename?: 'Confirmation', id: string, type: ConfirmationTypeEnum }> } | null };

export type UsersQueryVariables = Exact<{
  filterData?: InputMaybe<UsersFilterInput>;
  pagination: PaginationInput;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UsersWithPagination', users: Array<{ __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, company?: string | null, title?: string | null, ImageS3File?: { __typename?: 'ImagePreview', key: string, thumbnailSignedUrl?: string | null } | null }>, pagination: { __typename?: 'Pagination', from: number, length: number, total: number } } };

export type UpdateUserMutationVariables = Exact<{
  updateUserId: Scalars['ID']['input'];
  inputData: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', id: string, email: string, role: UserRoleEnum, hasPassword: boolean, firstName?: string | null, lastName?: string | null, gender?: GenderEnum | null, company?: string | null, title?: string | null, city?: string | null, country?: string | null, bio?: string | null, isSubscribedToNewsletter: boolean, ImageS3File?: { __typename?: 'ImagePreview', key: string, thumbnailSignedUrl?: string | null } | null, Confirmations: Array<{ __typename?: 'Confirmation', id: string, type: ConfirmationTypeEnum }> } | null };

export const FoodIngredientFragmentFragmentDoc = gql`
    fragment FoodIngredientFragment on FoodIngredient {
  id
  label
  category
  sourceType
  FoodIngredientNutrients {
    slug
    label
    unitName
    amount
  }
  FoodIngredientMeasures {
    id
    gramWeight
    label
  }
}
    `;
export const RatingFragmentFragmentDoc = gql`
    fragment RatingFragment on Rating {
  id
  parentRatingId
  articleSlug
  recipeSlug
  createdAt
  updatedAt
  rating
  content
  name
}
    `;
export const RecipeFragmentFragmentDoc = gql`
    fragment RecipeFragment on Recipe {
  slug
  createdAt
  updatedAt
  publishedAt
  title
  description
  servings
  timesJson
  averageRating
  numOfRatings
  MetaDataJson
  isBookmarked
  RecipeDirectionGroups {
    id
    index
    label
    RecipeDirections {
      id
      index
      description
    }
  }
  RecipeIngredientGroups {
    id
    index
    label
    RecipeIngredients {
      id
      index
      label
      amount
      contains
      FoodIngredient {
        id
        label
        FoodIngredientMeasures {
          id
          gramWeight
          label
        }
        FoodIngredientNutrients {
          slug
          amount
          label
          unitName
        }
      }
      FoodIngredientMeasure {
        id
        gramWeight
        label
      }
    }
  }
  MainImage {
    key
    thumbnailSignedUrl(size: Large)
  }
  RecipeExtraTips {
    id
    index
    description
  }
  freeFrom
}
    `;
export const PublicUserFragmentFragmentDoc = gql`
    fragment PublicUserFragment on PublicUser {
  id
  firstName
  lastName
  UserImage {
    key
    thumbnailSignedUrl(size: Medium)
  }
  title
  company
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  email
  role
  hasPassword
  firstName
  lastName
  gender
  company
  title
  city
  country
  bio
  isSubscribedToNewsletter
  ImageS3File {
    key
    thumbnailSignedUrl(size: Medium)
  }
  Confirmations {
    id
    type
  }
}
    `;
export const ArticleDocument = gql`
    query Article($slug: ID!) {
  article(slug: $slug) {
    slug
    createdAt
    updatedAt
    publishedAt
    title
    description
    content
    totalVotes
    Upvotes {
      ...PublicUserFragment
    }
    hasCurrentUserUpvoted
    hasCurrentUserDownvoted
    Author {
      ...PublicUserFragment
    }
    MainImage {
      key
      thumbnailSignedUrl(size: Large)
    }
  }
}
    ${PublicUserFragmentFragmentDoc}`;

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useArticleQuery(baseOptions: Apollo.QueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
      }
export function useArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
        }
export function useArticleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
        }
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleSuspenseQueryHookResult = ReturnType<typeof useArticleSuspenseQuery>;
export type ArticleQueryResult = Apollo.QueryResult<ArticleQuery, ArticleQueryVariables>;
export const ArticlesDocument = gql`
    query Articles($pagination: PaginationInput!) {
  articles(pagination: $pagination) {
    articles {
      slug
      createdAt
      updatedAt
      publishedAt
      title
      description
      content
      averageRating
      numOfRatings
      totalVotes
      hasCurrentUserUpvoted
      hasCurrentUserDownvoted
      Author {
        ...PublicUserFragment
      }
      Upvotes {
        ...PublicUserFragment
      }
      MainImage {
        key
        thumbnailSignedUrl(size: Large)
      }
    }
    pagination {
      from
      length
      total
    }
  }
}
    ${PublicUserFragmentFragmentDoc}`;

/**
 * __useArticlesQuery__
 *
 * To run a query within a React component, call `useArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useArticlesQuery(baseOptions: Apollo.QueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
      }
export function useArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
        }
export function useArticlesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
        }
export type ArticlesQueryHookResult = ReturnType<typeof useArticlesQuery>;
export type ArticlesLazyQueryHookResult = ReturnType<typeof useArticlesLazyQuery>;
export type ArticlesSuspenseQueryHookResult = ReturnType<typeof useArticlesSuspenseQuery>;
export type ArticlesQueryResult = Apollo.QueryResult<ArticlesQuery, ArticlesQueryVariables>;
export const CreateArticleDocument = gql`
    mutation CreateArticle($inputData: ArticleInput!) {
  createArticle(inputData: $inputData) {
    slug
  }
}
    `;
export type CreateArticleMutationFn = Apollo.MutationFunction<CreateArticleMutation, CreateArticleMutationVariables>;

/**
 * __useCreateArticleMutation__
 *
 * To run a mutation, you first call `useCreateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleMutation, { data, loading, error }] = useCreateArticleMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useCreateArticleMutation(baseOptions?: Apollo.MutationHookOptions<CreateArticleMutation, CreateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateArticleMutation, CreateArticleMutationVariables>(CreateArticleDocument, options);
      }
export type CreateArticleMutationHookResult = ReturnType<typeof useCreateArticleMutation>;
export type CreateArticleMutationResult = Apollo.MutationResult<CreateArticleMutation>;
export type CreateArticleMutationOptions = Apollo.BaseMutationOptions<CreateArticleMutation, CreateArticleMutationVariables>;
export const UpdateArticleDocument = gql`
    mutation UpdateArticle($inputData: ArticleInput!, $slug: ID!) {
  updateArticle(inputData: $inputData, slug: $slug) {
    slug
  }
}
    `;
export type UpdateArticleMutationFn = Apollo.MutationFunction<UpdateArticleMutation, UpdateArticleMutationVariables>;

/**
 * __useUpdateArticleMutation__
 *
 * To run a mutation, you first call `useUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMutation, { data, loading, error }] = useUpdateArticleMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUpdateArticleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateArticleMutation, UpdateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateArticleMutation, UpdateArticleMutationVariables>(UpdateArticleDocument, options);
      }
export type UpdateArticleMutationHookResult = ReturnType<typeof useUpdateArticleMutation>;
export type UpdateArticleMutationResult = Apollo.MutationResult<UpdateArticleMutation>;
export type UpdateArticleMutationOptions = Apollo.BaseMutationOptions<UpdateArticleMutation, UpdateArticleMutationVariables>;
export const DeletePostDocument = gql`
    mutation DeletePost($slug: ID!) {
  deleteArticle(slug: $slug)
}
    `;
export type DeletePostMutationFn = Apollo.MutationFunction<DeletePostMutation, DeletePostMutationVariables>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useDeletePostMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostMutation, DeletePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePostMutation, DeletePostMutationVariables>(DeletePostDocument, options);
      }
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = Apollo.MutationResult<DeletePostMutation>;
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<DeletePostMutation, DeletePostMutationVariables>;
export const VoteArticleDocument = gql`
    mutation VoteArticle($inputData: VoteInput!, $slug: ID!) {
  voteArticle(inputData: $inputData, slug: $slug) {
    slug
  }
}
    `;
export type VoteArticleMutationFn = Apollo.MutationFunction<VoteArticleMutation, VoteArticleMutationVariables>;

/**
 * __useVoteArticleMutation__
 *
 * To run a mutation, you first call `useVoteArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoteArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voteArticleMutation, { data, loading, error }] = useVoteArticleMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useVoteArticleMutation(baseOptions?: Apollo.MutationHookOptions<VoteArticleMutation, VoteArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoteArticleMutation, VoteArticleMutationVariables>(VoteArticleDocument, options);
      }
export type VoteArticleMutationHookResult = ReturnType<typeof useVoteArticleMutation>;
export type VoteArticleMutationResult = Apollo.MutationResult<VoteArticleMutation>;
export type VoteArticleMutationOptions = Apollo.BaseMutationOptions<VoteArticleMutation, VoteArticleMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    accessToken
    User {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SignOutDocument = gql`
    mutation SignOut($accessToken: String) {
  signOut(accessToken: $accessToken)
}
    `;
export type SignOutMutationFn = Apollo.MutationFunction<SignOutMutation, SignOutMutationVariables>;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useSignOutMutation(baseOptions?: Apollo.MutationHookOptions<SignOutMutation, SignOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignOutMutation, SignOutMutationVariables>(SignOutDocument, options);
      }
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = Apollo.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<SignOutMutation, SignOutMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($inputData: SignUpInput!) {
  signUp(inputData: $inputData)
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const SignInWithPasswordDocument = gql`
    mutation SignInWithPassword($inputData: SignInWithPasswordInput!) {
  signInWithPassword(inputData: $inputData) {
    accessToken
    User {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export type SignInWithPasswordMutationFn = Apollo.MutationFunction<SignInWithPasswordMutation, SignInWithPasswordMutationVariables>;

/**
 * __useSignInWithPasswordMutation__
 *
 * To run a mutation, you first call `useSignInWithPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInWithPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInWithPasswordMutation, { data, loading, error }] = useSignInWithPasswordMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useSignInWithPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SignInWithPasswordMutation, SignInWithPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInWithPasswordMutation, SignInWithPasswordMutationVariables>(SignInWithPasswordDocument, options);
      }
export type SignInWithPasswordMutationHookResult = ReturnType<typeof useSignInWithPasswordMutation>;
export type SignInWithPasswordMutationResult = Apollo.MutationResult<SignInWithPasswordMutation>;
export type SignInWithPasswordMutationOptions = Apollo.BaseMutationOptions<SignInWithPasswordMutation, SignInWithPasswordMutationVariables>;
export const SignInWithMagicLinkDocument = gql`
    mutation SignInWithMagicLink($token: String!) {
  signInWithMagicLink(token: $token) {
    accessToken
    User {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export type SignInWithMagicLinkMutationFn = Apollo.MutationFunction<SignInWithMagicLinkMutation, SignInWithMagicLinkMutationVariables>;

/**
 * __useSignInWithMagicLinkMutation__
 *
 * To run a mutation, you first call `useSignInWithMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInWithMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInWithMagicLinkMutation, { data, loading, error }] = useSignInWithMagicLinkMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSignInWithMagicLinkMutation(baseOptions?: Apollo.MutationHookOptions<SignInWithMagicLinkMutation, SignInWithMagicLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInWithMagicLinkMutation, SignInWithMagicLinkMutationVariables>(SignInWithMagicLinkDocument, options);
      }
export type SignInWithMagicLinkMutationHookResult = ReturnType<typeof useSignInWithMagicLinkMutation>;
export type SignInWithMagicLinkMutationResult = Apollo.MutationResult<SignInWithMagicLinkMutation>;
export type SignInWithMagicLinkMutationOptions = Apollo.BaseMutationOptions<SignInWithMagicLinkMutation, SignInWithMagicLinkMutationVariables>;
export const RequestMagicLinkDocument = gql`
    mutation RequestMagicLink($idOrEmail: String!) {
  requestMagicLink(idOrEmail: $idOrEmail)
}
    `;
export type RequestMagicLinkMutationFn = Apollo.MutationFunction<RequestMagicLinkMutation, RequestMagicLinkMutationVariables>;

/**
 * __useRequestMagicLinkMutation__
 *
 * To run a mutation, you first call `useRequestMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMagicLinkMutation, { data, loading, error }] = useRequestMagicLinkMutation({
 *   variables: {
 *      idOrEmail: // value for 'idOrEmail'
 *   },
 * });
 */
export function useRequestMagicLinkMutation(baseOptions?: Apollo.MutationHookOptions<RequestMagicLinkMutation, RequestMagicLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestMagicLinkMutation, RequestMagicLinkMutationVariables>(RequestMagicLinkDocument, options);
      }
export type RequestMagicLinkMutationHookResult = ReturnType<typeof useRequestMagicLinkMutation>;
export type RequestMagicLinkMutationResult = Apollo.MutationResult<RequestMagicLinkMutation>;
export type RequestMagicLinkMutationOptions = Apollo.BaseMutationOptions<RequestMagicLinkMutation, RequestMagicLinkMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($inputData: UpdatePasswordInput!) {
  updatePassword(inputData: $inputData)
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($idOrEmail: String!) {
  requestPasswordReset(idOrEmail: $idOrEmail)
}
    `;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      idOrEmail: // value for 'idOrEmail'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, options);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $newPassword: String!) {
  resetPassword(token: $token, newPassword: $newPassword)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const PasswordSignUpDocument = gql`
    mutation PasswordSignUp($inputData: PasswordSignUpInput!) {
  passwordSignUp(inputData: $inputData) {
    accessToken
    User {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export type PasswordSignUpMutationFn = Apollo.MutationFunction<PasswordSignUpMutation, PasswordSignUpMutationVariables>;

/**
 * __usePasswordSignUpMutation__
 *
 * To run a mutation, you first call `usePasswordSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordSignUpMutation, { data, loading, error }] = usePasswordSignUpMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function usePasswordSignUpMutation(baseOptions?: Apollo.MutationHookOptions<PasswordSignUpMutation, PasswordSignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordSignUpMutation, PasswordSignUpMutationVariables>(PasswordSignUpDocument, options);
      }
export type PasswordSignUpMutationHookResult = ReturnType<typeof usePasswordSignUpMutation>;
export type PasswordSignUpMutationResult = Apollo.MutationResult<PasswordSignUpMutation>;
export type PasswordSignUpMutationOptions = Apollo.BaseMutationOptions<PasswordSignUpMutation, PasswordSignUpMutationVariables>;
export const SetBookmarkDocument = gql`
    mutation SetBookmark($inputData: BookmarkInput!) {
  setBookmark(inputData: $inputData)
}
    `;
export type SetBookmarkMutationFn = Apollo.MutationFunction<SetBookmarkMutation, SetBookmarkMutationVariables>;

/**
 * __useSetBookmarkMutation__
 *
 * To run a mutation, you first call `useSetBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBookmarkMutation, { data, loading, error }] = useSetBookmarkMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useSetBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<SetBookmarkMutation, SetBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBookmarkMutation, SetBookmarkMutationVariables>(SetBookmarkDocument, options);
      }
export type SetBookmarkMutationHookResult = ReturnType<typeof useSetBookmarkMutation>;
export type SetBookmarkMutationResult = Apollo.MutationResult<SetBookmarkMutation>;
export type SetBookmarkMutationOptions = Apollo.BaseMutationOptions<SetBookmarkMutation, SetBookmarkMutationVariables>;
export const DeleteConfirmationDocument = gql`
    mutation DeleteConfirmation($deleteConfirmationId: ID!) {
  deleteConfirmation(id: $deleteConfirmationId)
}
    `;
export type DeleteConfirmationMutationFn = Apollo.MutationFunction<DeleteConfirmationMutation, DeleteConfirmationMutationVariables>;

/**
 * __useDeleteConfirmationMutation__
 *
 * To run a mutation, you first call `useDeleteConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConfirmationMutation, { data, loading, error }] = useDeleteConfirmationMutation({
 *   variables: {
 *      deleteConfirmationId: // value for 'deleteConfirmationId'
 *   },
 * });
 */
export function useDeleteConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConfirmationMutation, DeleteConfirmationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConfirmationMutation, DeleteConfirmationMutationVariables>(DeleteConfirmationDocument, options);
      }
export type DeleteConfirmationMutationHookResult = ReturnType<typeof useDeleteConfirmationMutation>;
export type DeleteConfirmationMutationResult = Apollo.MutationResult<DeleteConfirmationMutation>;
export type DeleteConfirmationMutationOptions = Apollo.BaseMutationOptions<DeleteConfirmationMutation, DeleteConfirmationMutationVariables>;
export const FoodIngredientDocument = gql`
    query FoodIngredient($id: ID!) {
  foodIngredient(id: $id) {
    ...FoodIngredientFragment
  }
}
    ${FoodIngredientFragmentFragmentDoc}`;

/**
 * __useFoodIngredientQuery__
 *
 * To run a query within a React component, call `useFoodIngredientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodIngredientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodIngredientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFoodIngredientQuery(baseOptions: Apollo.QueryHookOptions<FoodIngredientQuery, FoodIngredientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodIngredientQuery, FoodIngredientQueryVariables>(FoodIngredientDocument, options);
      }
export function useFoodIngredientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodIngredientQuery, FoodIngredientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodIngredientQuery, FoodIngredientQueryVariables>(FoodIngredientDocument, options);
        }
export function useFoodIngredientSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FoodIngredientQuery, FoodIngredientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FoodIngredientQuery, FoodIngredientQueryVariables>(FoodIngredientDocument, options);
        }
export type FoodIngredientQueryHookResult = ReturnType<typeof useFoodIngredientQuery>;
export type FoodIngredientLazyQueryHookResult = ReturnType<typeof useFoodIngredientLazyQuery>;
export type FoodIngredientSuspenseQueryHookResult = ReturnType<typeof useFoodIngredientSuspenseQuery>;
export type FoodIngredientQueryResult = Apollo.QueryResult<FoodIngredientQuery, FoodIngredientQueryVariables>;
export const FoodIngredientsDocument = gql`
    query FoodIngredients($pagination: PaginationInput!, $filterData: FoodIngredientFilterInput) {
  foodIngredients(pagination: $pagination, filterData: $filterData) {
    foodIngredients {
      ...FoodIngredientFragment
    }
    pagination {
      from
      length
      total
    }
  }
}
    ${FoodIngredientFragmentFragmentDoc}`;

/**
 * __useFoodIngredientsQuery__
 *
 * To run a query within a React component, call `useFoodIngredientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodIngredientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodIngredientsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filterData: // value for 'filterData'
 *   },
 * });
 */
export function useFoodIngredientsQuery(baseOptions: Apollo.QueryHookOptions<FoodIngredientsQuery, FoodIngredientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodIngredientsQuery, FoodIngredientsQueryVariables>(FoodIngredientsDocument, options);
      }
export function useFoodIngredientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodIngredientsQuery, FoodIngredientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodIngredientsQuery, FoodIngredientsQueryVariables>(FoodIngredientsDocument, options);
        }
export function useFoodIngredientsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FoodIngredientsQuery, FoodIngredientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FoodIngredientsQuery, FoodIngredientsQueryVariables>(FoodIngredientsDocument, options);
        }
export type FoodIngredientsQueryHookResult = ReturnType<typeof useFoodIngredientsQuery>;
export type FoodIngredientsLazyQueryHookResult = ReturnType<typeof useFoodIngredientsLazyQuery>;
export type FoodIngredientsSuspenseQueryHookResult = ReturnType<typeof useFoodIngredientsSuspenseQuery>;
export type FoodIngredientsQueryResult = Apollo.QueryResult<FoodIngredientsQuery, FoodIngredientsQueryVariables>;
export const UpdateFoodIngredientDocument = gql`
    mutation UpdateFoodIngredient($id: ID!, $inputData: FoodIngredientInput!) {
  updateFoodIngredient(id: $id, inputData: $inputData) {
    ...FoodIngredientFragment
  }
}
    ${FoodIngredientFragmentFragmentDoc}`;
export type UpdateFoodIngredientMutationFn = Apollo.MutationFunction<UpdateFoodIngredientMutation, UpdateFoodIngredientMutationVariables>;

/**
 * __useUpdateFoodIngredientMutation__
 *
 * To run a mutation, you first call `useUpdateFoodIngredientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFoodIngredientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFoodIngredientMutation, { data, loading, error }] = useUpdateFoodIngredientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useUpdateFoodIngredientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFoodIngredientMutation, UpdateFoodIngredientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFoodIngredientMutation, UpdateFoodIngredientMutationVariables>(UpdateFoodIngredientDocument, options);
      }
export type UpdateFoodIngredientMutationHookResult = ReturnType<typeof useUpdateFoodIngredientMutation>;
export type UpdateFoodIngredientMutationResult = Apollo.MutationResult<UpdateFoodIngredientMutation>;
export type UpdateFoodIngredientMutationOptions = Apollo.BaseMutationOptions<UpdateFoodIngredientMutation, UpdateFoodIngredientMutationVariables>;
export const SubscribeToNewsletterDocument = gql`
    mutation SubscribeToNewsletter($email: String!) {
  subscribeToNewsletter(email: $email)
}
    `;
export type SubscribeToNewsletterMutationFn = Apollo.MutationFunction<SubscribeToNewsletterMutation, SubscribeToNewsletterMutationVariables>;

/**
 * __useSubscribeToNewsletterMutation__
 *
 * To run a mutation, you first call `useSubscribeToNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToNewsletterMutation, { data, loading, error }] = useSubscribeToNewsletterMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSubscribeToNewsletterMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeToNewsletterMutation, SubscribeToNewsletterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeToNewsletterMutation, SubscribeToNewsletterMutationVariables>(SubscribeToNewsletterDocument, options);
      }
export type SubscribeToNewsletterMutationHookResult = ReturnType<typeof useSubscribeToNewsletterMutation>;
export type SubscribeToNewsletterMutationResult = Apollo.MutationResult<SubscribeToNewsletterMutation>;
export type SubscribeToNewsletterMutationOptions = Apollo.BaseMutationOptions<SubscribeToNewsletterMutation, SubscribeToNewsletterMutationVariables>;
export const RatingsDocument = gql`
    query Ratings($recipeSlug: ID, $articleSlug: ID, $pagination: PaginationInput!) {
  ratings(
    recipeSlug: $recipeSlug
    articleSlug: $articleSlug
    pagination: $pagination
  ) {
    ratings {
      ...RatingFragment
      replies {
        ...RatingFragment
      }
    }
    pagination {
      from
      length
      total
    }
  }
}
    ${RatingFragmentFragmentDoc}`;

/**
 * __useRatingsQuery__
 *
 * To run a query within a React component, call `useRatingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRatingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatingsQuery({
 *   variables: {
 *      recipeSlug: // value for 'recipeSlug'
 *      articleSlug: // value for 'articleSlug'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useRatingsQuery(baseOptions: Apollo.QueryHookOptions<RatingsQuery, RatingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RatingsQuery, RatingsQueryVariables>(RatingsDocument, options);
      }
export function useRatingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RatingsQuery, RatingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RatingsQuery, RatingsQueryVariables>(RatingsDocument, options);
        }
export function useRatingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RatingsQuery, RatingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RatingsQuery, RatingsQueryVariables>(RatingsDocument, options);
        }
export type RatingsQueryHookResult = ReturnType<typeof useRatingsQuery>;
export type RatingsLazyQueryHookResult = ReturnType<typeof useRatingsLazyQuery>;
export type RatingsSuspenseQueryHookResult = ReturnType<typeof useRatingsSuspenseQuery>;
export type RatingsQueryResult = Apollo.QueryResult<RatingsQuery, RatingsQueryVariables>;
export const CreateRatingDocument = gql`
    mutation CreateRating($inputData: RatingInput!) {
  createRating(inputData: $inputData) {
    ...RatingFragment
  }
}
    ${RatingFragmentFragmentDoc}`;
export type CreateRatingMutationFn = Apollo.MutationFunction<CreateRatingMutation, CreateRatingMutationVariables>;

/**
 * __useCreateRatingMutation__
 *
 * To run a mutation, you first call `useCreateRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRatingMutation, { data, loading, error }] = useCreateRatingMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useCreateRatingMutation(baseOptions?: Apollo.MutationHookOptions<CreateRatingMutation, CreateRatingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRatingMutation, CreateRatingMutationVariables>(CreateRatingDocument, options);
      }
export type CreateRatingMutationHookResult = ReturnType<typeof useCreateRatingMutation>;
export type CreateRatingMutationResult = Apollo.MutationResult<CreateRatingMutation>;
export type CreateRatingMutationOptions = Apollo.BaseMutationOptions<CreateRatingMutation, CreateRatingMutationVariables>;
export const RecipeDocument = gql`
    query Recipe($slug: ID!) {
  recipe(slug: $slug) {
    ...RecipeFragment
    content
  }
}
    ${RecipeFragmentFragmentDoc}`;

/**
 * __useRecipeQuery__
 *
 * To run a query within a React component, call `useRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useRecipeQuery(baseOptions: Apollo.QueryHookOptions<RecipeQuery, RecipeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecipeQuery, RecipeQueryVariables>(RecipeDocument, options);
      }
export function useRecipeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipeQuery, RecipeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecipeQuery, RecipeQueryVariables>(RecipeDocument, options);
        }
export function useRecipeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RecipeQuery, RecipeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecipeQuery, RecipeQueryVariables>(RecipeDocument, options);
        }
export type RecipeQueryHookResult = ReturnType<typeof useRecipeQuery>;
export type RecipeLazyQueryHookResult = ReturnType<typeof useRecipeLazyQuery>;
export type RecipeSuspenseQueryHookResult = ReturnType<typeof useRecipeSuspenseQuery>;
export type RecipeQueryResult = Apollo.QueryResult<RecipeQuery, RecipeQueryVariables>;
export const RecipesDocument = gql`
    query Recipes($pagination: PaginationInput!) {
  recipes(pagination: $pagination) {
    recipes {
      ...RecipeFragment
    }
    pagination {
      from
      length
      total
    }
  }
}
    ${RecipeFragmentFragmentDoc}`;

/**
 * __useRecipesQuery__
 *
 * To run a query within a React component, call `useRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useRecipesQuery(baseOptions: Apollo.QueryHookOptions<RecipesQuery, RecipesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecipesQuery, RecipesQueryVariables>(RecipesDocument, options);
      }
export function useRecipesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipesQuery, RecipesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecipesQuery, RecipesQueryVariables>(RecipesDocument, options);
        }
export function useRecipesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RecipesQuery, RecipesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecipesQuery, RecipesQueryVariables>(RecipesDocument, options);
        }
export type RecipesQueryHookResult = ReturnType<typeof useRecipesQuery>;
export type RecipesLazyQueryHookResult = ReturnType<typeof useRecipesLazyQuery>;
export type RecipesSuspenseQueryHookResult = ReturnType<typeof useRecipesSuspenseQuery>;
export type RecipesQueryResult = Apollo.QueryResult<RecipesQuery, RecipesQueryVariables>;
export const BookmarkedRecipesDocument = gql`
    query BookmarkedRecipes($pagination: PaginationInput!) {
  bookmarkedRecipes(pagination: $pagination) {
    recipes {
      ...RecipeFragment
    }
    pagination {
      from
      length
      total
    }
  }
}
    ${RecipeFragmentFragmentDoc}`;

/**
 * __useBookmarkedRecipesQuery__
 *
 * To run a query within a React component, call `useBookmarkedRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookmarkedRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookmarkedRecipesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useBookmarkedRecipesQuery(baseOptions: Apollo.QueryHookOptions<BookmarkedRecipesQuery, BookmarkedRecipesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookmarkedRecipesQuery, BookmarkedRecipesQueryVariables>(BookmarkedRecipesDocument, options);
      }
export function useBookmarkedRecipesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookmarkedRecipesQuery, BookmarkedRecipesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookmarkedRecipesQuery, BookmarkedRecipesQueryVariables>(BookmarkedRecipesDocument, options);
        }
export function useBookmarkedRecipesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BookmarkedRecipesQuery, BookmarkedRecipesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BookmarkedRecipesQuery, BookmarkedRecipesQueryVariables>(BookmarkedRecipesDocument, options);
        }
export type BookmarkedRecipesQueryHookResult = ReturnType<typeof useBookmarkedRecipesQuery>;
export type BookmarkedRecipesLazyQueryHookResult = ReturnType<typeof useBookmarkedRecipesLazyQuery>;
export type BookmarkedRecipesSuspenseQueryHookResult = ReturnType<typeof useBookmarkedRecipesSuspenseQuery>;
export type BookmarkedRecipesQueryResult = Apollo.QueryResult<BookmarkedRecipesQuery, BookmarkedRecipesQueryVariables>;
export const CreateRecipeDocument = gql`
    mutation CreateRecipe($inputData: RecipeInput!) {
  createRecipe(inputData: $inputData) {
    ...RecipeFragment
  }
}
    ${RecipeFragmentFragmentDoc}`;
export type CreateRecipeMutationFn = Apollo.MutationFunction<CreateRecipeMutation, CreateRecipeMutationVariables>;

/**
 * __useCreateRecipeMutation__
 *
 * To run a mutation, you first call `useCreateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecipeMutation, { data, loading, error }] = useCreateRecipeMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useCreateRecipeMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecipeMutation, CreateRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecipeMutation, CreateRecipeMutationVariables>(CreateRecipeDocument, options);
      }
export type CreateRecipeMutationHookResult = ReturnType<typeof useCreateRecipeMutation>;
export type CreateRecipeMutationResult = Apollo.MutationResult<CreateRecipeMutation>;
export type CreateRecipeMutationOptions = Apollo.BaseMutationOptions<CreateRecipeMutation, CreateRecipeMutationVariables>;
export const UpdateRecipeDocument = gql`
    mutation UpdateRecipe($inputData: RecipeInput!, $slug: ID!) {
  updateRecipe(inputData: $inputData, slug: $slug) {
    ...RecipeFragment
  }
}
    ${RecipeFragmentFragmentDoc}`;
export type UpdateRecipeMutationFn = Apollo.MutationFunction<UpdateRecipeMutation, UpdateRecipeMutationVariables>;

/**
 * __useUpdateRecipeMutation__
 *
 * To run a mutation, you first call `useUpdateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecipeMutation, { data, loading, error }] = useUpdateRecipeMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUpdateRecipeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecipeMutation, UpdateRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecipeMutation, UpdateRecipeMutationVariables>(UpdateRecipeDocument, options);
      }
export type UpdateRecipeMutationHookResult = ReturnType<typeof useUpdateRecipeMutation>;
export type UpdateRecipeMutationResult = Apollo.MutationResult<UpdateRecipeMutation>;
export type UpdateRecipeMutationOptions = Apollo.BaseMutationOptions<UpdateRecipeMutation, UpdateRecipeMutationVariables>;
export const DeleteRecipeDocument = gql`
    mutation DeleteRecipe($slug: ID!) {
  deleteRecipe(slug: $slug)
}
    `;
export type DeleteRecipeMutationFn = Apollo.MutationFunction<DeleteRecipeMutation, DeleteRecipeMutationVariables>;

/**
 * __useDeleteRecipeMutation__
 *
 * To run a mutation, you first call `useDeleteRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecipeMutation, { data, loading, error }] = useDeleteRecipeMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useDeleteRecipeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRecipeMutation, DeleteRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRecipeMutation, DeleteRecipeMutationVariables>(DeleteRecipeDocument, options);
      }
export type DeleteRecipeMutationHookResult = ReturnType<typeof useDeleteRecipeMutation>;
export type DeleteRecipeMutationResult = Apollo.MutationResult<DeleteRecipeMutation>;
export type DeleteRecipeMutationOptions = Apollo.BaseMutationOptions<DeleteRecipeMutation, DeleteRecipeMutationVariables>;
export const FileUploadS3PresignDocument = gql`
    mutation FileUploadS3Presign($fileName: String!, $fileType: FileTypeEnum!, $contentType: String!, $fileSize: Int!, $isPublic: Boolean) {
  fileUploadS3Presign(
    fileName: $fileName
    fileType: $fileType
    contentType: $contentType
    fileSize: $fileSize
    isPublic: $isPublic
  ) {
    signedRequest
    key
  }
}
    `;
export type FileUploadS3PresignMutationFn = Apollo.MutationFunction<FileUploadS3PresignMutation, FileUploadS3PresignMutationVariables>;

/**
 * __useFileUploadS3PresignMutation__
 *
 * To run a mutation, you first call `useFileUploadS3PresignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileUploadS3PresignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileUploadS3PresignMutation, { data, loading, error }] = useFileUploadS3PresignMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      fileType: // value for 'fileType'
 *      contentType: // value for 'contentType'
 *      fileSize: // value for 'fileSize'
 *      isPublic: // value for 'isPublic'
 *   },
 * });
 */
export function useFileUploadS3PresignMutation(baseOptions?: Apollo.MutationHookOptions<FileUploadS3PresignMutation, FileUploadS3PresignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileUploadS3PresignMutation, FileUploadS3PresignMutationVariables>(FileUploadS3PresignDocument, options);
      }
export type FileUploadS3PresignMutationHookResult = ReturnType<typeof useFileUploadS3PresignMutation>;
export type FileUploadS3PresignMutationResult = Apollo.MutationResult<FileUploadS3PresignMutation>;
export type FileUploadS3PresignMutationOptions = Apollo.BaseMutationOptions<FileUploadS3PresignMutation, FileUploadS3PresignMutationVariables>;
export const UserDocument = gql`
    query User($userId: ID!) {
  user(id: $userId) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export function useUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query Users($filterData: UsersFilterInput, $pagination: PaginationInput!) {
  users(filterData: $filterData, pagination: $pagination) {
    users {
      id
      firstName
      lastName
      company
      title
      ImageS3File {
        key
        thumbnailSignedUrl(size: Medium)
      }
    }
    pagination {
      from
      length
      total
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filterData: // value for 'filterData'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export function useUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($updateUserId: ID!, $inputData: UpdateUserInput!) {
  updateUser(id: $updateUserId, inputData: $inputData) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      updateUserId: // value for 'updateUserId'
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;