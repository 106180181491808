/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {
  GenderEnum,
  MeQuery,
  useMeQuery,
  UserRoleEnum,
  useSignOutMutation,
} from '../../../generated/graphql'

const ME_REFETCH_IN_MS = 180_000

interface IUserContext {
  data: IAuthData
  setData: React.Dispatch<IAuthData>
  signout: (skipLogoutMutation?: boolean) => Promise<void>
  signoutData: ReturnType<typeof useSignOutMutation>[1] | undefined
  isLoggedIn: boolean
  isLoading: boolean
  hasRoles: (roles: Array<UserRoleEnum>) => boolean
}

interface IAuthProviderProps {
  children: React.ReactNode
}

export interface IAuthData {
  accessToken: string | null
  User: Partial<NonNullable<MeQuery['me']>['User']>
}

const INITIAL_AUTH: IAuthData = {
  accessToken: null,
  User: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    role: UserRoleEnum.User,
    gender: GenderEnum.Male,
    hasPassword: false,
    Confirmations: [],
  },
}

export const UserContext = React.createContext<IUserContext>({
  data: INITIAL_AUTH,
  setData: () => {},
  signout: () => Promise.resolve(),
  signoutData: undefined,
  isLoggedIn: false,
  isLoading: false,
  hasRoles: () => false,
})

export const useAuth = () => React.useContext(UserContext)

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [data, setData] = React.useState<IAuthData>(INITIAL_AUTH)
  const [isLoggingIn, setIsLoggingIn] = React.useState(true)
  const [signout, signoutData] = useSignOutMutation()

  const { data: queryData, loading: isMeQueryLoading } = useMeQuery({
    onCompleted: ({ me }) => {
      const { accessToken, User } = me ?? INITIAL_AUTH
      setData({
        accessToken,
        User,
      })
      setIsLoggingIn(false)
    },
    onError: () => {
      setData(INITIAL_AUTH)
      setIsLoggingIn(false)
    },
    pollInterval: ME_REFETCH_IN_MS,
  })

  React.useEffect(() => {
    const { accessToken, User } = queryData?.me ?? INITIAL_AUTH

    setData({
      accessToken,
      User,
    })
  }, [JSON.stringify(queryData?.me)])

  const isLoggedIn = !isLoggingIn && !!data.accessToken
  const hasRoles = React.useCallback(
    (roles: Array<UserRoleEnum>) => isLoggedIn && roles.includes(data.User.role as UserRoleEnum),
    [data.User.role, isLoggedIn]
  )

  const handleLogout = React.useCallback(
    async (skipLogoutMutation?: boolean) => {
      try {
        if (!skipLogoutMutation) {
          await signout()
        }
        setData(INITIAL_AUTH)
      } catch (e) {
        // ignore error
      }
    },
    [signout, setData]
  )

  const contextValue: IUserContext = React.useMemo(
    () => ({
      data,
      setData,
      signout: handleLogout,
      signoutData,
      isLoggedIn,
      isLoading: isLoggingIn || isMeQueryLoading,
      hasRoles,
    }),
    [data, handleLogout, hasRoles, isMeQueryLoading, isLoggingIn, signoutData]
  )

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}
